import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    DELETE_RAW_MATERIAL,
    DELETE_RAW_MATERIAL_FAIL,
    DELETE_RAW_MATERIAL_SUCCESS,
    GET_ALL_RAW_MATERIAL,
    GET_ALL_RAW_MATERIAL_FAIL,
    GET_ALL_RAW_MATERIAL_SUCCESS,
    RAW_MATERIAL_ADD,
    RAW_MATERIAL_ADD_FAIL,
    RAW_MATERIAL_ADD_SUCCESS,
} from '../../actions/RawMaterials/rawmaterail.action'

const initialState = {
    rawMaterialArr: null,
    loading: false,
    error: null,
}

export const rawMaterialReducer = (state = initialState, action) => {
    switch (action.type) {
        case RAW_MATERIAL_ADD:
            return {
                ...state,
                categoryArr: null,
                loading: true,
                error: null,
            }
        case RAW_MATERIAL_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case RAW_MATERIAL_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_RAW_MATERIAL:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_RAW_MATERIAL_SUCCESS:
            return {
                ...state,
                rawMaterialArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_RAW_MATERIAL_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_RAW_MATERIAL:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_RAW_MATERIAL_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                rawMaterialArr: state.rawMaterialArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_RAW_MATERIAL_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
