export const Roles = {
    admin: "ADMIN",
    manager: "MANAGER",
    SUB_ADMIN: "SUB_ADMIN",
    MOULDING: "MOULDING",
    ASSEMBLY: "ASSEMBLY",
    PLATING: "PLATING",
    DISPATCH: "DISPATCH",
    STORE: "STORE",
    QC_PDI: "QC_PDI",
    PPC: "PPC",
    SUPERVISOR: "SUPERVISOR",
    INSPECTOR: "INSPECTOR",
    STORE_EXE: "STORE_EXE",

}