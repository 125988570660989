import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    MOULD_ADD,
    MOULD_ADD_SUCCESS,
    MOULD_ADD_FAIL,
    GET_ALL_MOULD,
    GET_ALL_MOULD_SUCCESS,
    GET_ALL_MOULD_FAIL,
    DELETE_MOULD,
    DELETE_MOULD_SUCCESS,
    DELETE_MOULD_FAIL,
} from '../../actions/Mould/mould.action'

const initialState = {
    mouldArr: null,
    loading: false,
    error: null,
}

export const mouldReducer = (state = initialState, action) => {
    switch (action.type) {
        case MOULD_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case MOULD_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case MOULD_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_MOULD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_MOULD_SUCCESS:
            return {
                ...state,
                mouldArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_MOULD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_MOULD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_MOULD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                mouldArr: state.mouldArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_MOULD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
