import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/Rack`

export const addRack = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllRack = () => {
    return axios.get(`${url}`)
}

export const deleteRack = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getRackByBlockId = async (id) => {
    return axios.get(`${url}/getByBlockId/${id}`)
}
