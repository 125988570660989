import React, { useEffect, useState } from 'react'
import { SimpleCard } from 'app/components'
import { Box, styled } from '@mui/system'
import { Table, TableHead, TextField, TableCell, TableBody, IconButton, Icon, TableRow, Button, Typography } from '@mui/material'
import Modal from '@mui/material/Modal'

import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { deleteRawMaterial, getAllRawMaterial } from 'app/services/rawMaterial.service'
import { getAllRawMaterialData } from 'app/redux/actions/RawMaterials/rawmaterail.action'
import { useDispatch, useSelector } from 'react-redux'
import { UNITS } from 'app/utils/constant'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ContentTable from 'app/components/Table/ContentTable'
import Select from 'react-select';
import { getRawMaterailStockById, issueRawMaterialById } from 'app/services/PendingStockGroup.service'
import moment from 'moment'
export default function RawMaterialIssue() {
    // const [rawmaterialArr, setRawmaterialArr] = useState([])
    const rawmaterialArr = useSelector((state) => state.rawMaterials.rawMaterialArr)
    const [selectedRawMaterialObj, setSelectedRawMaterialObj] = useState('')
    const [rawMaterialLocationArr, setRawMaterialLocationArr] = useState([]);
    const [selectedStockLocationObj, setSelectedStockLocationObj] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [scannedbarcode, setScannedbarcode] = useState("")
    const [stock, setStock] = useState(false)
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [searchParams, setSearchParams] = useSearchParams("")
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid rgba(0,0,0,0.1)',
        boxShadow: 24,
        p: 4,
    }

    useEffect(() => {
        getRawMaterial()
    }, [])

    const getRawMaterial = async () => {
        try {
            dispatch(getAllRawMaterialData())
        } catch (error) {
            toastError(error)
        }
    }



    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const handleStockSelect = (id) => {
        console.log(id,"CHECK")
        if (rawmaterialArr && rawmaterialArr.length > 0) {
            console.log(id,"CHECK 1" )
            console.log(rawmaterialArr,"CHECK")
            let tempObj = rawmaterialArr.find(el => el._id == id);
            console.log(tempObj,"CHECK 2")
            if (tempObj) {
                getSelectedStockLocationData(tempObj)
            }
        }
    }

    useEffect(() => {
        if (rawmaterialArr &&  rawmaterialArr.length > 0 && searchParams.get("id"))
            handleStockSelect(searchParams.get("id"))
    }, [searchParams.get("id"), rawmaterialArr])

    const getSelectedStockLocationData = async (obj) => {
        try {
            console.log(obj,"CHECK THIS OUT")
            if (obj && obj?._id != "") {
                setSelectedStockLocationObj(obj)
                let { data: res } = await getRawMaterailStockById(obj._id);
                if (res) {
                    console.log(res.data)
                    setRawMaterialLocationArr(res.data)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleVerify = async () => {
        try {
            console.log(scannedbarcode, selectedStockLocationObj)
            if (scannedbarcode == selectedStockLocationObj?.barcodeId) {
                
                let {data:res}=await issueRawMaterialById(selectedStockLocationObj._id);
                if(res){
                    toastSuccess(res.message)
                }
                
                // alert("Barcode Verified")
            }
            else {
                alert("Invalid barcode")
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    return (
        <SimpleCard>

            {/* <TextField placeholder='' /> */}

            <Select value={selectedRawMaterialObj} onChange={(e) => getSelectedStockLocationData(e)} options={rawmaterialArr?.map(el => ({ ...el, label: el.name, value: el._id }))} />

            {rawMaterialLocationArr && rawMaterialLocationArr.length > 0 &&
                <>

                    <ContentTable
                        data={rawMaterialLocationArr}
                        columns={[
                            {
                                name: 'Name',
                                selector: (row) => row?.rawMaterial?.name,
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'Description',
                                selector: (row) => row?.rawMaterial?.description,
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'Part No',
                                selector: (row) => row?.partNo,
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'LOT No',
                                selector: (row) => row?.lotNo,
                                searchable: true,
                                sortable: true,
                            },

                            {
                                name: 'MRN No',
                                selector: (row) => row?.mrnNo,
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'In Stock Qty.',
                                selector: (row) => row?.qty,
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'CreatedAt',
                                selector: (row) => moment(row?.createdAt).format("DD-MM-YYYY HH:mm:ss a"),
                                searchable: true,
                                sortable: true,
                            },
                            {
                                name: 'Action',
                                cell: (row, i) => <>
                                    {i == 0 ?
                                        <Button onClick={(e) => { setOpen(e); setSelectedStockLocationObj(row); setStock(row?.qty) }}>Issue</Button>
                                        :
                                        <></>}
                                </>,
                            },


                        ]}


                    />
                </>
            }
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Scan barcode
                    </Typography>
                    <TextField name="name" onChange={(e) => setScannedbarcode(e.target.value)} value={scannedbarcode} label={"barcode"} sx={{ width: '100%', mt: 2 }} />
                    <Button variant='contained' sx={{ mt: 4 }} onClick={(e) => handleVerify()}>Verify And Submit</Button>
                </Box>
            </Modal>
        </SimpleCard>
    )
}
