import { addClient, deleteClient, getAllClient } from '../../../services/client.service'

/////////////////////////////////////ADD USER
export const CLIENT_ADD = 'CLIENT_ADD'
export const CLIENT_ADD_SUCCESS = 'CLIENT_ADD_SUCCESS'
export const CLIENT_ADD_FAIL = 'CLIENT_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_CLIENT = 'GET_ALL_CLIENT'
export const GET_ALL_CLIENT_SUCCESS = 'GET_ALL_CLIENT_SUCCESS'
export const GET_ALL_CLIENT_FAIL = 'GET_ALL_CLIENT_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_CLIENT = 'DELETE_CLIENT'
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS'
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL'

export const addClientData = (formData) => async (dispatch) => {
    try {
        // console.log(formData, dispatch)
        dispatch({ type: CLIENT_ADD })
        let { data: response } = await addClient(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: CLIENT_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: CLIENT_ADD_FAIL, payload: err })
    }
}


export const getAllClientData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CLIENT })
        let { data: response } = await getAllClient(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_CLIENT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_CLIENT_FAIL, payload: err })
    }
}


export const deleteClientData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CLIENT })
        let { data: response } = await deleteClient(formData)
        if (response) {
            dispatch({
                type: DELETE_CLIENT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_CLIENT_FAIL, payload: err })
    }
}
