import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/ITProducts`

export const addITProducts = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllITProducts = () => {
    return axios.get(`${url}`)
}

export const deleteITProducts = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}


export const updateItProducts = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}


export const IssueItProductObj = (id, obj) => {
    return axios.patch(`${url}/Issue/${id}`, obj)
}


export const getITProductsByRackId = async (id) => {
    return axios.get(`${url}/getByRackId/${id}`)
}
