import { addstaffAllocation, deletestaffAllocation, getAllstaffAllocation } from '../../../services/StaffAllocation'

/////////////////////////////////////ADD USER
export const STAFF_ALLOCATION_ADD = 'STAFF_ALLOCATION_ADD'
export const STAFF_ALLOCATION_ADD_SUCCESS = 'STAFF_ALLOCATION_ADD_SUCCESS'
export const STAFF_ALLOCATION_ADD_FAIL = 'STAFF_ALLOCATION_ADD_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_STAFF_ALLOCATION = 'GET_ALL_STAFF_ALLOCATION'
export const GET_ALL_STAFF_ALLOCATION_SUCCESS = 'GET_ALL_STAFF_ALLOCATION_SUCCESS'
export const GET_ALL_STAFF_ALLOCATION_FAIL = 'GET_ALL_STAFF_ALLOCATION_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_STAFF_ALLOCATION = 'DELETE_STAFF_ALLOCATION'
export const DELETE_STAFF_ALLOCATION_SUCCESS = 'DELETE_STAFF_ALLOCATION_SUCCESS'
export const DELETE_STAFF_ALLOCATION_FAIL = 'DELETE_STAFF_ALLOCATION_FAIL'

export const addstaffAllocationData = (formData) => async (dispatch) => {
    try {
        console.log(formData, dispatch)
        dispatch({ type: STAFF_ALLOCATION_ADD })
        let { data: response } = await addstaffAllocation(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: STAFF_ALLOCATION_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: STAFF_ALLOCATION_ADD_FAIL, payload: err })
    }
}


export const getAllstaffAllocationData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_STAFF_ALLOCATION })
        let { data: response } = await getAllstaffAllocation(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_STAFF_ALLOCATION_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_STAFF_ALLOCATION_FAIL, payload: err })
    }
}


export const deletestaffAllocationData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_STAFF_ALLOCATION })
        let { data: response } = await deletestaffAllocation(formData)
        if (response) {
            dispatch({
                type: DELETE_STAFF_ALLOCATION_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_STAFF_ALLOCATION_FAIL, payload: err })
    }
}
