import { addUser, getAllUsers, deleteUser, updateUserById, getById, getAllSupervisorUsers, getAllInspectorUsers } from '../../../services/user.service'

/////////////////////////////////////ADD USER
export const USER_ADD = 'USER_ADD'
export const USER_ADD_SUCCESS = 'USER_ADD_SUCCESS'
export const USER_ADD_FAIL = 'USER_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS'
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_SUPERVISOR_USERS = 'GET_ALL_SUPERVISOR_USERS'
export const GET_ALL_SUPERVISOR_USERS_SUCCESS = 'GET_ALL_SUPERVISOR_USERS_SUCCESS'
export const GET_ALL_SUPERVISOR_USERS_FAIL = 'GET_ALL_SUPERVISOR_USERS_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_INSPECTOR_USERS = 'GET_ALL_INSPECTOR_USERS'
export const GET_ALL_INSPECTOR_USERS_SUCCESS = 'GET_ALL_INSPECTOR_USERS_SUCCESS'
export const GET_ALL_INSPECTOR_USERS_FAIL = 'GET_ALL_INSPECTOR_USERS_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_USERS = 'DELETE_USERS'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAIL = 'DELETE_USERS_FAIL'

/////////////////////////////////////UPDATE USER
export const UPDATE_USERS = 'UPDATE_USERS'
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS'
export const UPDATE_USERS_FAIL = 'UPDATE_USERS_FAIL'


/////////////////////////////////////GETBYID USER
export const GET_BY_ID_USERS = 'GET_BY_ID_USERS'
export const GET_BY_ID_USERS_SUCCESS = 'GET_BY_ID_USERS_SUCCESS'
export const GET_BY_ID_USERS_FAIL = 'GET_BY_ID_USERS_FAIL'

export const addUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: USER_ADD })
        let { data: response } = await addUser(formData)
        if (response) {
            dispatch({
                type: USER_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: USER_ADD_FAIL, payload: err })
    }
}


export const getAllUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_USERS })
        let { data: response } = await getAllUsers(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_USERS_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_USERS_FAIL, payload: err })
    }
}
export const getAllInspectorUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_INSPECTOR_USERS })
        let { data: response } = await getAllInspectorUsers(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_INSPECTOR_USERS_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_INSPECTOR_USERS_FAIL, payload: err })
    }
}


export const getAllSupervisorUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_SUPERVISOR_USERS })
        let { data: response } = await getAllSupervisorUsers(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_SUPERVISOR_USERS_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_SUPERVISOR_USERS_FAIL, payload: err })
    }
}


export const deleteUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_USERS })
        let { data: response } = await deleteUser(formData)
        if (response) {
            dispatch({
                type: DELETE_USERS_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_USERS_FAIL, payload: err })
    }
}



export const updateUserData = (id, formData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_USERS })
        let { data: response } = await updateUserById(id, formData)
        if (response) {
            dispatch({
                type: UPDATE_USERS_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: UPDATE_USERS_FAIL, payload: err })
    }
}


export const getByIdUserData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_BY_ID_USERS })
        let { data: response } = await getById(formData)
        if (response) {
            dispatch({
                type: GET_BY_ID_USERS_SUCCESS,
                payload: {
                    ...response.data,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_BY_ID_USERS_FAIL, payload: err })
    }
}
