import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/mouldingRequirementSheet`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addMouldingRequirementSheet = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllMouldingRequirementSheet = () => {
    return axios.get(`${url}/`)
}

export const getAllMouldingRequirementSheetByDateAll = (startDate, endDate) => {
    return axios.get(`${url}/getbydatesAdmin/${startDate}/${endDate}`)
}
export const getAllMouldingRequirementSheetByDate = (
    startDate,
    endDate,
    clientId
) => {
    return axios.get(`${url}/getbydates/${startDate}/${endDate}/${clientId}`)
}

export const deleteMouldingRequirementSheet = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
