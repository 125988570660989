import { addMouldingRequirementSheet, deleteMouldingRequirementSheet, getAllMouldingRequirementSheet, getAllMouldingRequirementSheetByDate } from '../../../services/MouldingRequirementSheet'

/////////////////////////////////////ADD USER
export const MOULDING_REQUIREMENT_SHEET_ADD = 'MOULDING_REQUIREMENT_SHEET_ADD'
export const MOULDING_REQUIREMENT_SHEET_ADD_SUCCESS = 'MOULDING_REQUIREMENT_SHEET_ADD_SUCCESS'
export const MOULDING_REQUIREMENT_SHEET_ADD_FAIL = 'MOULDING_REQUIREMENT_SHEET_ADD_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_MOULDING_REQUIREMENT_SHEET = 'GET_ALL_MOULDING_REQUIREMENT_SHEET'
export const GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS = 'GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS'
export const GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL = 'GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES = 'GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES'
export const GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_SUCCESS = 'GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_SUCCESS'
export const GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_FAIL = 'GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_FAIL'



/////////////////////////////////////DELETE USER
export const DELETE_MOULDING_REQUIREMENT_SHEET = 'DELETE_MOULDING_REQUIREMENT_SHEET'
export const DELETE_MOULDING_REQUIREMENT_SHEET_SUCCESS = 'DELETE_MOULDING_REQUIREMENT_SHEET_SUCCESS'
export const DELETE_MOULDING_REQUIREMENT_SHEET_FAIL = 'DELETE_MOULDING_REQUIREMENT_SHEET_FAIL'

export const addMouldingRequirementSheetData = (formData) => async (dispatch) => {
    try {
        console.log(formData, dispatch)
        dispatch({ type: MOULDING_REQUIREMENT_SHEET_ADD })
        let { data: response } = await addMouldingRequirementSheet(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: MOULDING_REQUIREMENT_SHEET_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: MOULDING_REQUIREMENT_SHEET_ADD_FAIL, payload: err })
    }
}


export const getAllMouldingRequirementSheetData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MOULDING_REQUIREMENT_SHEET })
        let { data: response } = await getAllMouldingRequirementSheet(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL, payload: err })
    }
}


export const getAllMouldingRequirementSheetDataByDates = (startDate, endDate, clientId) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MOULDING_REQUIREMENT_SHEET })
        let { data: response } = await getAllMouldingRequirementSheetByDate(startDate, endDate, clientId)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL, payload: err })
    }
}

export const deleteMouldingRequirementSheetData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_MOULDING_REQUIREMENT_SHEET })
        let { data: response } = await deleteMouldingRequirementSheet(formData)
        if (response) {
            dispatch({
                type: DELETE_MOULDING_REQUIREMENT_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_MOULDING_REQUIREMENT_SHEET_FAIL, payload: err })
    }
}
