import { addMould, deleteMould, getAllMould } from '../../../services/mould.service'

/////////////////////////////////////ADD USER
export const MOULD_ADD = 'MOULD_ADD'
export const MOULD_ADD_SUCCESS = 'MOULD_ADD_SUCCESS'
export const MOULD_ADD_FAIL = 'MOULD_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_MOULD = 'GET_ALL_MOULD'
export const GET_ALL_MOULD_SUCCESS = 'GET_ALL_MOULD_SUCCESS'
export const GET_ALL_MOULD_FAIL = 'GET_ALL_MOULD_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_MOULD = 'DELETE_MOULD'
export const DELETE_MOULD_SUCCESS = 'DELETE_MOULD_SUCCESS'
export const DELETE_MOULD_FAIL = 'DELETE_MOULD_FAIL'

export const addMouldData = (formData) => async (dispatch) => {
    try {
        console.log(formData, dispatch)
        dispatch({ type: MOULD_ADD })
        let { data: response } = await addMould(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: MOULD_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: MOULD_ADD_FAIL, payload: err })
    }
}


export const getAllMouldData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MOULD })
        let { data: response } = await getAllMould(formData)
        console.log(response, "response")
        if (response) {
            dispatch({
                type: GET_ALL_MOULD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_MOULD_FAIL, payload: err })
    }
}


export const deleteMouldData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_MOULD })
        let { data: response } = await deleteMould(formData)
        if (response) {
            dispatch({
                type: DELETE_MOULD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_MOULD_FAIL, payload: err })
    }
}
