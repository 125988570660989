import { addMachine, deleteMachine, getAllMachine } from '../../../services/machine.service'

/////////////////////////////////////ADD USER
export const MACHINE_ADD = 'MACHINE_ADD'
export const MACHINE_ADD_SUCCESS = 'MACHINE_ADD_SUCCESS'
export const MACHINE_ADD_FAIL = 'MACHINE_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_MACHINE = 'GET_ALL_MACHINE'
export const GET_ALL_MACHINE_SUCCESS = 'GET_ALL_MACHINE_SUCCESS'
export const GET_ALL_MACHINE_FAIL = 'GET_ALL_MACHINE_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_MACHINE = 'DELETE_MACHINE'
export const DELETE_MACHINE_SUCCESS = 'DELETE_MACHINE_SUCCESS'
export const DELETE_MACHINE_FAIL = 'DELETE_MACHINE_FAIL'

export const addMachineData = (formData) => async (dispatch) => {
    try {
        // console.log(formData, dispatch)
        dispatch({ type: MACHINE_ADD })
        let { data: response } = await addMachine(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: MACHINE_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: MACHINE_ADD_FAIL, payload: err })
    }
}


export const getAllMachineData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MACHINE })
        let { data: response } = await getAllMachine(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_MACHINE_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_MACHINE_FAIL, payload: err })
    }
}


export const deleteMachineData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_MACHINE })
        let { data: response } = await deleteMachine(formData)
        if (response) {
            dispatch({
                type: DELETE_MACHINE_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_MACHINE_FAIL, payload: err })
    }
}
