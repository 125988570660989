import { flat } from 'app/utils/utils'
import { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AllPages } from '../routes/routes'

import { useSelector } from 'react-redux'
import { Roles } from 'Roles'

// const getUserRoleAuthStatus = (pathname, user, routes) => {
//     if (!user) {
//         return false
//     }
//     const matched = routes.find((r) => r.path === pathname)

//     const authenticated =
//         matched && matched.auth && matched.auth.length
//             ? matched.auth.includes(user.role)
//             : true
//     console.log(matched, user)
//     return authenticated
// }

const LoginGuard = ({ children, role = ['ADMIN'] }) => {
    const auth = useSelector((state) => state.auth)

    // return <>{isAuthenticated ? children : <Navigate to="/session/signin" />}</>

    const [previouseRoute, setPreviousRoute] = useState(null)
    const { pathname } = useLocation()
    const routes = flat(AllPages())
    console.log(auth)
    // console.log(user)

    // const isUserRoleAuthenticated = getUserRoleAuthStatus(
    //     pathname,
    //     user,
    //     routes
    // )
    // let authenticated = isAuthenticated && isUserRoleAuthenticated

    // IF YOU NEED ROLE BASED AUTHENTICATION,
    // UNCOMMENT ABOVE TWO LINES, getUserRoleAuthStatus METHOD AND user VARIABLE
    // AND COMMENT OUT BELOW LINE

    // let authenticated = isAuthenticated

    useEffect(() => {
        if (previouseRoute !== null) setPreviousRoute(pathname)
    }, [pathname, previouseRoute])

    if (!auth.isAuthorized) return <>{children}</>
    else {
        return (
            <>
                {auth.role == Roles.admin ? (
                    <Navigate
                        to="/dashboard/default"
                        state={{ redirectUrl: previouseRoute }}
                    />
                ) : (

                    <Navigate
                        to="/EmptyPage"
                        state={{ redirectUrl: previouseRoute }}
                    />
                )}
            </>

            // <Redirect
            //     to={{
            //         pathname: '/session/signin',
            //         state: { redirectUrl: previouseRoute },
            //     }}
            // />
        )
    }
}

export default LoginGuard
