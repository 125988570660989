import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import { authReducer } from './auth/auth.reducer'
import { userReducer } from './User/user.reducer'
import { mouldingCategoriesReducer } from './MouldingCategories/mouldingCategory.reducer'
import { rawMaterialReducer } from './RawMaterials/rawMaterial.reducer'
import { productReducer } from './Product/product.reducer'
import { machineReducer } from './Machines/machine.reducer'
import { clientReducer } from './Client/client.reducer'
import { mouldReducer } from './Mould/mould.reducer'
import { schedulingSheetReducer } from './SchedulingSheet/SchedulingSheet.reducer'
import { staffAllocationReducer } from './StaffAllocation/StaffAllocation.reducer'
import { mouldingRequirementSheetReducer } from './RequirementSheet/MouldingRequirementSheet.reducer'
import { platingRequirementSheetReducer } from './RequirementSheet/PlatingRequirementSheet.reducer'
import { shiftDataReducer } from './ShiftData/ShiftData.reducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    auth: authReducer,
    user: userReducer,
    mouldingCategories: mouldingCategoriesReducer,
    rawMaterials: rawMaterialReducer,
    product: productReducer,
    machine: machineReducer,
    client: clientReducer,
    mould: mouldReducer,
    schedulingSheet: schedulingSheetReducer,
    staffAllocation: staffAllocationReducer,
    mouldingRequirementSheet: mouldingRequirementSheetReducer,
    platingRequirementSheet: platingRequirementSheetReducer,
    shiftData: shiftDataReducer,
})

export default RootReducer
