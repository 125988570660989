import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteJigById, getAllJigs, updateJigById } from 'app/services/Jigs.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
export default function ViewJigs() {
    const [displayArr, setDisplayArr] = useState([])



    useEffect(() => {
        getJigs()
    }, [])

    const getJigs = async () => {
        try {
            let { data: res } = await getAllJigs()
            if (res) {
                setDisplayArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }


    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Jig?')) {
                const res = await deleteJigById(id)
                if (res.data?.message) alert(res.data.message)
            }
        } catch (error) {
            toastError(error)
        }
        getJigs()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const handleUpateStatus = async (value, id) => {
        try {
            if (window.confirm("Do your really want to update jig status")) {

                const { data: res } = await updateJigById(id, { status: value });
                if (res) {
                    alert(res.message)
                    getJigs()
                }
            }
        } catch (error) {
            toastError(error)
        }
    }


    return (
        <Container>
            <SimpleCard title={'Manage Jigs'}>

                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Jig Number</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>QTY/JIG</TableCell>
                            <TableCell>Qty Flight Bar</TableCell>
                            {/* <TableCell>Status</TableCell> */}
                            {/* <TableCell>OK</TableCell>
                            <TableCell>NG</TableCell>
                            <TableCell>Repair</TableCell> */}
                            <TableCell>Delete</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayArr &&
                            displayArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row?.arr?.reduce((acc, el) => acc + el.jigNumber + ', ', '')}</TableCell>
                                    <TableCell>{row.productName}</TableCell>
                                    <TableCell>{row.qtyOrJigs}</TableCell>
                                    <TableCell>{row.qtyFlightBar}</TableCell>
                                    {/* <TableCell>{row.status}</TableCell> */}
                                    {/* <TableCell>
                                        <Button variant="outlined" color="warning" onClick={() => handleUpateStatus(JIG_STATUES.OK, row._id)}>
                                            Mark OK
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="warning" onClick={() => handleUpateStatus(JIG_STATUES.NG, row._id)}>
                                            Mark NG
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="warning" onClick={() => handleUpateStatus(JIG_STATUES.REPAIR, row._id)}>
                                            Mark Repair
                                        </Button>
                                    </TableCell> */}
                                    <TableCell>
                                        <Button variant="outlined" color="error" onClick={() => alert('Unable to delete jig . please contact administrator')}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
