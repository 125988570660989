import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    CATEGORY_ADD,
    CATEGORY_ADD_SUCCESS,
    CATEGORY_ADD_FAIL,
    GET_ALL_CATEGORY,
    GET_ALL_CATEGORY_SUCCESS,
    GET_ALL_CATEGORY_FAIL,
    DELETE_CATEGORY,
    DELETE_CATEGORY_SUCCESS,
    DELETE_CATEGORY_FAIL,
} from '../../actions/MouldingCategories/mouldingCategory.action'

const initialState = {
    categoryArr: null,
    loading: false,
    error: null,
}

export const mouldingCategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case CATEGORY_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case CATEGORY_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_CATEGORY:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_CATEGORY_SUCCESS:
            return {
                ...state,
                categoryArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_CATEGORY_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_CATEGORY:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_CATEGORY_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                categoryArr: state.categoryArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_CATEGORY_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
