import React, { useState, useEffect } from 'react'
import { Breadcrumb, SimpleCard } from 'app/components'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import {
    Grid,
    TextField,
    Button,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material'
import { USERS } from 'app/utils/constant'
import { ADD_USER, updateUserData } from 'app/redux/actions/User/user.actions'
import { loginUser } from 'app/redux/actions/auth/auth.actions'
import { toast } from 'react-toastify'
import { toastSuccess, toastError } from 'app/utils/toastUtils'
import {
    addUserData,
    getByIdUserData,
} from 'app/redux/actions/User/user.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

const UpdateUser = () => {
    const userObj = useSelector((state) => state.user.userObj)
    const navigate = useNavigate()

    const params = useParams()
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    // const [password, setPassword] = useState('')
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState(USERS.ADMIN)

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                email,
                // password,
                phone,
                role,
            }
            dispatch(updateUserData(params.id, obj))
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    const handleGetUser = async () => {
        try {
            dispatch(getByIdUserData(params.id))
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetUser()
        console.log(userObj)
        console.log(params)
    }, [])

    useEffect(() => {
        setUserDetail()
    }, [userObj])

    const setUserDetail = () => {
        setName(userObj?.name)
        setEmail(userObj?.email)
        setPhone(userObj?.phone)
        setRole(userObj?.role)
    }

    return (
        <Container>
            <SimpleCard title="Add User">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <TextField
                        type="text"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email || ''}
                        label="Email"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <TextField
                        type="text"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone || ''}
                        label="Phone"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <RadioGroup
                        sx={{ mb: 2 }}
                        value={role || ''}
                        name="Role"
                        onChange={(e) => setRole(e.target.value)}
                        row
                    >
                        <FormControlLabel
                            value={USERS.ADMIN}
                            control={<Radio color="primary" />}
                            label="Admin"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.SUB_ADMIN}
                            control={<Radio color="primary" />}
                            label="Sub Admin"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.MOULDING}
                            control={<Radio color="primary" />}
                            label="Moulding"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.ASSEMBLY}
                            control={<Radio color="primary" />}
                            label="Assembly"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.PLATING}
                            control={<Radio color="primary" />}
                            label="PLating"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.DISPATCH}
                            control={<Radio color="primary" />}
                            label="Dispatch"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.STORE}
                            control={<Radio color="primary" />}
                            label="Store"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.QC_PDI}
                            control={<Radio color="primary" />}
                            label="QC/PDI"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.PPC}
                            control={<Radio color="primary" />}
                            label="PPC"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.SUPERVISOR}
                            control={<Radio color="primary" />}
                            label="Supervisor"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.INSPECTOR}
                            control={<Radio color="primary" />}
                            label="Inspector"
                            labelPlacement="end"
                        />
                        <FormControlLabel
                            value={USERS.MANAGER}
                            control={<Radio color="primary" />}
                            label="Manager"
                            labelPlacement="end"
                        />
                    </RadioGroup>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        type="submit"
                    >
                        Update User
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}

export default UpdateUser
