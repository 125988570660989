import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Paper, Typography, Box } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { getAllClientData } from 'app/redux/actions/Client/client.action'
import { getAllProductData, getAllMouldingProductData, getAllPlatingProductData } from 'app/redux/actions/Product/product.action'
import { addMouldingRequirementSheetData, getAllMouldingRequirementSheetDataByDates } from 'app/redux/actions/RequirementSheet/MouldingRequirementSheet.action'
import { addplatingRequirementSheetData, getAllplatingRequirementSheetDataByDates } from 'app/redux/actions/RequirementSheet/PlatingRequirementSheet.action'
import { addSchedulingSheetData, getAllSchedulingSheetDataByDates } from 'app/redux/actions/SchedulingSheet/Scheduling.action'
import { getAllSchedulingSheetByDate } from 'app/services/SchedulingSheet'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))
export default function AddSchedulingSheet() {
    const dispatch = useDispatch()
    const [isMouldingActive, setIsMouldingActive] = useState(true);

    const mouldingrequirementsheetArr = useSelector(state => state.mouldingRequirementSheet.mouldingRequirementSheetArr);
    const platingrequirementsheetArr = useSelector(state => state.platingRequirementSheet.platingRequirementSheetArr);
    const platingProductsArr = useSelector(state => state.product.platingProductsArr);
    const mouldingProductsArr = useSelector(state => state.product.platingProductsArr);
    const [selectedProductsArr, setSelectedProductsArr] = useState([]);
    const [datewiseDataArr, setDatewiseDataArr] = useState([]);

    const clientArr = useSelector(state => state.client.clientArr);
    const [clientId, setClientId] = useState("");
    const [showProducts, setShowProducts] = useState(true);
    const productArr = useSelector(state => state.product.productArr);
    const [localSchedulingSheet, setLocalSchedulingSheet] = useState([isMouldingActive ? mouldingrequirementsheetArr : platingrequirementsheetArr]);
    const [localProductsArr, setLocalProductsArr] = useState([productArr.map(el => {
        let obj = {
            datesArr: [],
            ...el,
        }
        return obj
    })]);



    const [dateArr, setDateArr] = useState([]);

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const handleSubmit = async () => {
        try {
            let finalArr = []
            if (clientId == "") {
                if (clientArr?.length)
                    alert("Please select a client first")
            }
            else {

                let ScheduleArr = localProductsArr.filter(el => el.changed).map(el => {
                    let arr = {}
                    arr = el.datesArr.filter(el => el.changed).map(ele => {
                        let obj2 = {
                            productId: el._id,
                            on: ele.on,
                            clientId: clientId,
                            quantity: ele.quantity
                        }
                        return obj2
                    })
                    finalArr.push(...arr)
                })
                if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
                    alert("Start Date cannot be greater than End Date")
                }
                else {
                    let FinalObj = {
                        scheduleArr: finalArr,
                    }
                    if (isMouldingActive) {
                        let tempArr = localProductsArr.map(el => {
                            let obj = {
                                ...el,
                                datesArr: el.datesArr.map(ele => {
                                    let obj2 = {
                                        ...ele,
                                        changed: false,
                                    }
                                    return obj2
                                }),
                                changed: false,
                            }
                            return obj
                        })
                        setLocalProductsArr(tempArr)
                        dispatch(addMouldingRequirementSheetData(FinalObj))
                    }
                    else {
                        let tempArr = localProductsArr.map(el => {
                            let obj = {
                                ...el,
                                datesArr: el.datesArr.map(ele => {
                                    let obj2 = {
                                        ...ele,
                                        changed: false,
                                    }
                                    return obj2
                                }),
                                changed: false,
                            }
                            return obj
                        })
                        setLocalProductsArr(tempArr)
                        dispatch(addplatingRequirementSheetData(FinalObj))
                    }

                }
            }


        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    const getDates = (startDate, stopDate) => {
        var dateArray = new Array();
        var currentDate = startDate;
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate));
            currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    useEffect(() => {
        handleGenerateDatesArr();
        if (isMouldingActive) {
            let tempArr = mouldingProductsArr.map(el => el._id)
            setSelectedProductsArr([...tempArr])
        }
        else {
            let tempArr = platingProductsArr.map(el => el._id)
            setSelectedProductsArr([...tempArr])
        }
    }, [mouldingProductsArr, platingProductsArr, isMouldingActive, clientId]) //


    useEffect(() => {
        if (clientArr?.length) {
            setClientId(clientArr[0]._id)
            dispatch(getAllMouldingProductData(clientArr[0]._id))
            dispatch(getAllPlatingProductData(clientArr[0]._id))
        }
    }, [clientArr]) //



    useEffect(() => {
        if (clientId) {
            // setClientId(clientArr[0]._id)
            dispatch(getAllMouldingProductData(clientId))
            dispatch(getAllPlatingProductData(clientId))
        }
    }, [clientId]) //


    const returnSelectedClient = () => {
        let obj = clientArr?.find(el => el._id == clientId)
        console.log("SELECTED", obj)
        return obj
    }


    useEffect(() => {
        if (isMouldingActive) {
            if (mouldingrequirementsheetArr) {
                setLocalSchedulingSheet(mouldingrequirementsheetArr)
                let tempProductsArr = localProductsArr.map(el => {
                    let obj = {
                        ...el
                    }
                    if (mouldingrequirementsheetArr.filter(elx => selectedProductsArr.some(eli => eli == elx.productId)).some(ele => ele.productId == el._id)) {
                        if (el.datesArr) {

                            el.datesArr = el.datesArr.map(ele => {
                                if (ele) {
                                    let Obj = mouldingrequirementsheetArr.filter(elx => selectedProductsArr.some(eli => eli == elx.productId)).find(elx => new Date(elx.on).getTime() == new Date(ele.on).getTime() && elx.productId == el._id)
                                    if (Obj)
                                        ele.quantity = Obj.quantity
                                }
                                return ele
                            })
                            console.log(el, "element")
                        }
                    }
                    return obj
                })
                console.log("user", tempProductsArr)
                setLocalProductsArr([...tempProductsArr])
                setDatewiseDataArr([...tempProductsArr])
            }
        }
        else {
            console.log(platingrequirementsheetArr, "platingrequirementsheetArr")
            if (platingrequirementsheetArr) {
                setLocalSchedulingSheet(platingrequirementsheetArr)
                let tempProductsArr = localProductsArr.map(el => {
                    let obj = {
                        ...el
                    }
                    if (platingrequirementsheetArr.filter(elx => selectedProductsArr.some(eli => eli == elx.productId)).some(ele => ele.productId == el._id)) {
                        if (el.datesArr) {

                            el.datesArr = el.datesArr.map(ele => {
                                if (ele) {
                                    let Obj = platingrequirementsheetArr.filter(elx => selectedProductsArr.some(eli => eli == elx.productId)).find(elx => new Date(elx.on).getTime() == new Date(ele.on).getTime() && elx.productId == el._id)
                                    if (Obj)
                                        ele.quantity = Obj.quantity
                                }
                                return ele
                            })
                            console.log(el, "element")
                        }
                    }

                    return obj
                })
                console.log("user", tempProductsArr)
                setLocalProductsArr([...tempProductsArr])
                setDatewiseDataArr([...tempProductsArr])
            }
        }
    }, [isMouldingActive, platingrequirementsheetArr, mouldingrequirementsheetArr])

    useEffect(() => {
        getDataOnInit()
    }, [])

    const getDataOnInit = async () => {
        try {
            dispatch(getAllClientData())
        } catch (error) {
            toastError(error)
        }
    }





    const handleGenerateDatesArr = async () => {
        if (clientId != "") {
            let DatesArr = getDates(new Date(startDate), new Date(endDate))
            if (isMouldingActive) {
                let tempProductsArr = mouldingProductsArr.filter(el => selectedProductsArr.some(ele => el._id == ele)).map(el => {
                    let obj = {
                        ...el,
                        datesArr: DatesArr.map(ele => {
                            let obj2 = {
                                on: ele,
                                quantity: 0
                            }
                            return obj2

                        })
                    }
                    return obj
                })
                setLocalProductsArr([...tempProductsArr])
                dispatch(getAllMouldingRequirementSheetDataByDates(startDate, endDate, clientId))
            }
            else {
                if (platingProductsArr) {

                    let tempProductsArr = platingProductsArr.filter(el => selectedProductsArr.some(ele => el._id == ele)).map(el => {
                        let obj = {
                            ...el,
                            datesArr: DatesArr.map(ele => {
                                let obj2 = {
                                    on: ele,
                                    quantity: 0
                                }
                                return obj2

                            })
                        }
                        return obj
                    })
                    setLocalProductsArr([...tempProductsArr])
                    dispatch(getAllplatingRequirementSheetDataByDates(startDate, endDate, clientId))
                }
            }
            setDateArr([...DatesArr])
        }
        else if (clientArr?.length) {

            alert("Please Select client first")
        }
        // setShowProducts(true)

    }

    const handleSetQuantity = (productId, dateIndex, value) => {
        setLocalProductsArr(previousValue => {

            // previousValue[productId].datesArr[dateId].quantity = value
            // previousValue[productId].changed = true
            // previousValue[productId].datesArr[dateId].changed = true
            // if (previousValue[productId].datesArr[dateId].quantity)
            //     previousValue[productId].datesArr[dateId].quantity = parseInt(previousValue[productId].datesArr[dateId].quantity)
            return [...previousValue.map(el => {
                if (el._id != productId) {
                    return el
                }
                else {
                    let obj = { ...el, changed: true }
                    obj.datesArr = obj.datesArr.map((ele, i) => (i == dateIndex ? { ...ele, quantity: value, changed: true } : ele))
                    return obj
                }

            })]
        })
    }



    const handleSelectProducts = (value) => {
        console.log(value)
        console.log(selectedProductsArr, "Asd")
        console.log(localProductsArr)
        let tempArr = datewiseDataArr.filter(el => value.some(ele => ele == el._id))
        console.log([...tempArr])
        setLocalProductsArr([...tempArr])
    }

    return (
        <Container>
            <SimpleCard title="Add Requirement Sheet">
                <Button variant={isMouldingActive ? "outlined" : "contained"} onClick={() => setIsMouldingActive(false)} color="primary" sx={{ mt: 2, mr: 3 }}>
                    Adding Requirement for Plating
                </Button>
                <Button variant={isMouldingActive ? "contained" : "outlined"} onClick={() => setIsMouldingActive(true)} color="primary" sx={{ mt: 2 }}>
                    Adding Requirement for Moulding
                </Button>
                <Button variant={isMouldingActive ? "contained" : "outlined"} onClick={() => setIsMouldingActive(true)} color="primary" sx={{ mt: 2 }}>
                    Adding Requirement for Assembly
                </Button>
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <Typography sx={{ mt: 3 }}>Select Client</Typography>
                    <Select
                        sx={{ mt: 3, width: 150 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={clientId}
                        label="Client Id"
                        onChange={e => setClientId(e.target.value)}
                    >
                        {
                            clientArr?.map(el =>
                                <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                            )
                        }
                    </Select>
                    <Typography sx={{ mt: 3 }}>Select Dates</Typography>
                    <TextField
                        type="date"
                        onChange={e => setStartDate(e.target.value)}
                        value={startDate}
                        label="Start Date"
                        sx={{ width: 250, mt: 2 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        onChange={e => setEndDate(e.target.value)}
                        value={endDate}
                        label="End Date"
                        sx={{ width: 250, mt: 2, ml: 2 }}
                    />

                    <Button onClick={() => handleGenerateDatesArr()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                        Generate Dates
                    </Button>


                    <Typography sx={{ mt: 3 }}>Select Products</Typography>
                    <Select
                        sx={{ mt: 3, minWidth: 150 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProductsArr}
                        multiple={true}
                        label="Products"
                        onChange={e => { handleSelectProducts(e.target.value); setSelectedProductsArr(e.target.value); console.log(e.target.value, "value") }}
                    >
                        {
                            isMouldingActive ?
                                mouldingProductsArr?.filter(elx => returnSelectedClient()?.productIdArr?.some(ele => ele.productId == elx._id)).map(el =>
                                    <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                                )
                                :
                                platingProductsArr?.filter(elx => returnSelectedClient()?.productIdArr?.some(ele => ele.productId == elx._id)).map(el =>
                                    <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                                )
                        }
                    </Select>




                    {
                        showProducts == true &&
                        <>
                            <Typography sx={{ mt: 3 }}>{isMouldingActive ? "Moulding" : "Plating"} Products</Typography>

                            <ContentTable
                                data={localProductsArr?.filter(elx => returnSelectedClient()?.productIdArr?.some(ele => ele.productId == elx._id))}
                                downloadInactive={true}
                                columns={[
                                    {
                                        name: 'Name',
                                        selector: row => row.name,
                                        sortable: true,
                                        searchable: true,
                                    },
                                    ...dateArr.map((el, i) => ({
                                        name: moment(el).format('ddd DD-MMM-YY'),
                                        cell: (row, index) =>
                                            <>
                                                <TextField
                                                    type="number"
                                                    onChange={e => handleSetQuantity(row._id, i, e.target.value)}
                                                    value={row?.datesArr[i]?.quantity ? row?.datesArr[i]?.quantity : 0}
                                                />
                                            </>
                                    }))
                                ]}
                            />

                            <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                                Save
                            </Button>
                        </>
                    }
                </form>
            </SimpleCard>
        </Container >
    )
}