import React from 'react'
import DataTable from 'react-data-table-component'
export default function Table({
    columns,
    data,
    title,
    dataTableProps,
    ...props
}) {
    const customStyles = {
        rows: {
            style: {
                // minWidth: "150px",
            },
        },
        headCells: {
            style: {
                // minWidth: "150px",
            },
        },
        cells: {
            style: {
                minWidth: '150px !important',
                // border: "1px solid black",
            },
        },
    }
    return (
        <div style={{ overflowX: 'auto', width: '100%' }}>
            <DataTable
                style={{ width: '100%' }}
                title={title ? title : false}
                columns={columns}
                data={data}
                customStyles={customStyles}
                pagination
                {...dataTableProps}
            />
        </div>
    )
}
