import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import LoginGuard from 'app/auth/LoginGuard'
import DownloadQr from '../admin/MrnQc/DownloadQr'
import DownloadItQr from '../admin/ITProducts/DownloadItQr'

const NotFound = Loadable(lazy(() => import('./NotFound')))
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')))
const JwtLogin = Loadable(lazy(() => import('./login/JwtLogin')))
const JwtRegister = Loadable(lazy(() => import('./register/JwtRegister')))

const sessionRoutes = [
    {
        path: '/session/signup',
        element: (
            <LoginGuard>
                <JwtRegister />
            </LoginGuard>
        ),
    },
    {
        path: '/session/signin',
        element: (
            <LoginGuard>
                <JwtLogin />
            </LoginGuard>
        ),
    },
    {
        path: '/session/forgot-password',
        element: (
            <LoginGuard>
                <ForgotPassword />
            </LoginGuard>
        ),
    },
    {
        path: '/DownloadQr/:id',
        element: <DownloadQr />,
    },
    {
        path: '/DownloadItQr',
        element: <DownloadItQr />,
    },
    {
        path: '/session/404',
        element: <NotFound />,
    },
]

export default sessionRoutes
