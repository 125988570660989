import {
    Button, Table, TableBody, TableCell, TableHead, TableRow
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteMachineData, getAllMachineData } from 'app/redux/actions/Machines/machine.action'
import { deleteMouldData, getAllMouldData } from 'app/redux/actions/Mould/mould.action'
import { deleteMachine, getAllMachine } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ViewMould() {
    const mouldArr = useSelector(state => state.mould.mouldArr);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // console.log(mouldArr, "asd")
    // const [machineArr, setMachineArr] = useState([])

    useEffect(() => {
        getAllMoulding()
    }, [])

    const getAllMoulding = async () => {
        try {
            dispatch(getAllMouldData())

        } catch (error) {
            toastError(error)
        }
    }


    const handleUpdate = async (id) => {
        try {
            navigate(`/admin/updatemould/${id}`)

        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this machine?')) {
                dispatch(deleteMouldData(id))
            }

        } catch (error) {
            toastError(error)
        }
        getAllMoulding()
    }


    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Moulds'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mould Number</TableCell>
                            <TableCell>Cycle Time</TableCell>
                            <TableCell>Products</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mouldArr && mouldArr.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell>
                                    {row?.name}
                                </TableCell>
                                <TableCell>
                                    {row?.mouldNumber ? row?.mouldNumber : "NA"}
                                </TableCell>
                                <TableCell>
                                    {row?.cycleTime}
                                </TableCell>
                                <TableCell>
                                    {row?.productArr.map((el, i, arr) => `${el?.productObj?.name}${i < arr.length - 1 ? "," : ""}\n`)}
                                </TableCell>
                                <TableCell><Button variant='outlined' color='primary' onClick={() => handleUpdate(row._id)}>Update</Button></TableCell>
                                <TableCell><Button variant='outlined' color='error' onClick={() => handleDelete(row._id)}>Delete</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
