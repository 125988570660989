import { MatxTheme } from 'app/components'
import { AuthProvider } from 'app/contexts/JWTAuthContext'
import { SettingsProvider } from 'app/contexts/SettingsContext'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import '../fake-db'
import './App.css'
import Loading from './components/MatxLoading/MatxLoading'
import { persistor, Store } from './redux/Store'
import { AllPages } from './routes/routes'
const App = () => {
    const all_pages = useRoutes(AllPages())
    return (
        <Provider store={Store}>
            <PersistGate loading={<Loading />} persistor={persistor}>
                <SettingsProvider>
                    <MatxTheme>
                        <AuthProvider>{all_pages}</AuthProvider>
                    </MatxTheme>
                </SettingsProvider>
                <Toaster />
            </PersistGate>
        </Provider>
    )
}

export default App
