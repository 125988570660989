import { addRawMaterial, deleteRawMaterial, getAllRawMaterial } from '../../../services/rawMaterial.service'

/////////////////////////////////////ADD USER
export const RAW_MATERIAL_ADD = 'RAW_MATERIAL_ADD'
export const RAW_MATERIAL_ADD_SUCCESS = 'RAW_MATERIAL_ADD_SUCCESS'
export const RAW_MATERIAL_ADD_FAIL = 'RAW_MATERIAL_ADD_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_RAW_MATERIAL = 'GET_ALL_RAW_MATERIAL'
export const GET_ALL_RAW_MATERIAL_SUCCESS = 'GET_ALL_RAW_MATERIAL_SUCCESS'
export const GET_ALL_RAW_MATERIAL_FAIL = 'GET_ALL_RAW_MATERIAL_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_RAW_MATERIAL = 'DELETE_RAW_MATERIAL'
export const DELETE_RAW_MATERIAL_SUCCESS = 'DELETE_RAW_MATERIAL_SUCCESS'
export const DELETE_RAW_MATERIAL_FAIL = 'DELETE_RAW_MATERIAL_FAIL'

export const addRawMaterialData = (formData) => async (dispatch) => {
    try {
        console.log(formData, dispatch)
        dispatch({ type: RAW_MATERIAL_ADD })
        let { data: response } = await addRawMaterial(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: RAW_MATERIAL_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: RAW_MATERIAL_ADD_FAIL, payload: err })
    }
}


export const getAllRawMaterialData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_RAW_MATERIAL })
        let { data: response } = await getAllRawMaterial(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_RAW_MATERIAL_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_RAW_MATERIAL_FAIL, payload: err })
    }
}


export const deleteRawMaterialData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_RAW_MATERIAL })
        let { data: response } = await deleteRawMaterial(formData)
        if (response) {
            dispatch({
                type: DELETE_RAW_MATERIAL_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_RAW_MATERIAL_FAIL, payload: err })
    }
}
