import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    DELETE_SCHEDULING_SHEET,
    DELETE_SCHEDULING_SHEET_FAIL,
    DELETE_SCHEDULING_SHEET_SUCCESS,
    GET_ALL_SCHEDULING_SHEET,
    GET_ALL_SCHEDULING_SHEET_FAIL,
    GET_ALL_SCHEDULING_SHEET_SUCCESS,
    SCHEDULING_SHEET_ADD,
    SCHEDULING_SHEET_ADD_FAIL,
    SCHEDULING_SHEET_ADD_SUCCESS,
    GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES,
    GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_FAIL,
    GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_SUCCESS
} from '../../actions/SchedulingSheet/Scheduling.action'

const initialState = {
    schedulingSheetArr: null,
    loading: false,
    error: null,
}

export const schedulingSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case SCHEDULING_SHEET_ADD:
            return {
                ...state,
                categoryArr: null,
                loading: true,
                error: null,
            }
        case SCHEDULING_SHEET_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case SCHEDULING_SHEET_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_SCHEDULING_SHEET:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_SCHEDULING_SHEET_SUCCESS:
            return {
                ...state,
                schedulingSheetArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_SCHEDULING_SHEET_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }




        case GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_SUCCESS:
            return {
                ...state,
                schedulingSheetArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_SCHEDULING_SHEET:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_SCHEDULING_SHEET_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                schedulingSheetArr: state.schedulingSheetArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_SCHEDULING_SHEET_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
