// export const API_URL = "http://192.168.0.4:4000/api"
import { url } from 'app/services/url.service'

export const API_URL = url

export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const USERS = {
    ADMIN: 'ADMIN',
    SUB_ADMIN: 'SUB_ADMIN',
    MOULDING: 'MOULDING',
    ASSEMBLY: 'ASSEMBLY',
    PLATING: 'PLATING',
    DISPATCH: 'DISPATCH',
    STORE: 'STORE',
    QC_PDI: 'QC_PDI',
    STORE_EXE: "STORE_EXE",
    PPC: 'PPC',
    SUPERVISOR: 'SUPERVISOR',
    INSPECTOR: 'INSPECTOR',
    MANAGER: 'MANAGER',
}

export const CATEGORY = {
    ASSEMBLY: 'ASSEMBLY',
    MOULDING: 'MOULDING',
    PLATING: 'PLATING',
    LAB: 'LAB',
    MAINTAINANCE: 'MAINTAINANCE',
    STAIONARY: 'STATIONARY',
    MISC: 'MISC',
    TOOLS: 'TOOLS',

}

export const UNITS = {
    KG_BAG: 'KG_BAG',
    KG_CAN: 'KG_CAN',
    LITERS_CAN: 'LITERS_CAN',
    UNIT: 'UNIT',
    PC_PER_PACKET: "Pc/Packet"
}

export const WEIGHT_UNITS = {
    kg: 'kg',
    gm: 'gm',
    l: 'L',
    ml: 'ml',
}

export const PRODUCT_TYPES = {
    IMPORTED: 'IMPORTED',
    IN_HOUSE: 'IN_HOUSE',
}

export const MaterialTestCertificateArr = [
    {
        label: 'Yes',
        value: 'Yes',
    },
    {
        label: 'No',
        value: 'No',
    },
]

export const USER_ACCESS_ARR = [
    { label: 'Users', value: 'Users' },
    { label: 'Moulding Categories', value: 'Moulding Categories' },
    { label: 'Product', value: 'Product' },
    { label: 'Machine', value: 'Machine' },
    { label: 'Staff Allocation', value: 'Staff Allocation' },
    { label: 'Raw Materials', value: 'Raw Materials' },
    { label: 'Client', value: 'Client' },
    { label: 'Mould', value: 'Mould' },
    { label: 'Assembly Product', value: 'Assembly Product' },
    { label: 'Scheduling', value: 'Scheduling' },
]

export const MrnReasons = {
    DAMAGED_PACKING: 'Damaged Packing',
    SHORT_QUANTITY: 'Short Quantity',
    EXPIRY_MATERIAL: 'Expiry Material',
    TESTING_NG: 'Testing Ng',
    OTHERS: 'Others',
}


export const DEFECT_CATEGORY = {
    Moulding: "Moulding",
    Plating: "Plating",
    Assembly: "Assembly",
    QC: "QC",
    MRN: "MRN"
}

export const JIG_STATUES = {
    OK: "ok",
    REPAIR: "repair",
    NG: "ng"
}