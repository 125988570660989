import { addSchedulingSheet, deleteSchedulingSheet, getAllSchedulingSheet, getAllSchedulingSheetByDate } from '../../../services/SchedulingSheet'

/////////////////////////////////////ADD USER
export const SCHEDULING_SHEET_ADD = 'SCHEDULING_SHEET_ADD'
export const SCHEDULING_SHEET_ADD_SUCCESS = 'SCHEDULING_SHEET_ADD_SUCCESS'
export const SCHEDULING_SHEET_ADD_FAIL = 'SCHEDULING_SHEET_ADD_FAIL'

/////////////////////////////////////GET USER
export const GET_ALL_SCHEDULING_SHEET = 'GET_ALL_SCHEDULING_SHEET'
export const GET_ALL_SCHEDULING_SHEET_SUCCESS = 'GET_ALL_SCHEDULING_SHEET_SUCCESS'
export const GET_ALL_SCHEDULING_SHEET_FAIL = 'GET_ALL_SCHEDULING_SHEET_FAIL'



/////////////////////////////////////GET USER
export const GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES = 'GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES'
export const GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_SUCCESS = 'GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_SUCCESS'
export const GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_FAIL = 'GET_ALL_SCHEDULING_SHEET_DATA_BY_DATES_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_SCHEDULING_SHEET = 'DELETE_SCHEDULING_SHEET'
export const DELETE_SCHEDULING_SHEET_SUCCESS = 'DELETE_SCHEDULING_SHEET_SUCCESS'
export const DELETE_SCHEDULING_SHEET_FAIL = 'DELETE_SCHEDULING_SHEET_FAIL'

export const addSchedulingSheetData = (formData) => async (dispatch) => {
    try {
        console.log(formData, )
        dispatch({ type: SCHEDULING_SHEET_ADD })
        let { data: response } = await addSchedulingSheet(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: SCHEDULING_SHEET_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: SCHEDULING_SHEET_ADD_FAIL, payload: err })
    }
}


export const getAllSchedulingSheetData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_SCHEDULING_SHEET })
        let { data: response } = await getAllSchedulingSheet(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_SCHEDULING_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_SCHEDULING_SHEET_FAIL, payload: err })
    }
}

export const getAllSchedulingSheetDataByDates = (startDate, endDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_SCHEDULING_SHEET })
        let { data: response } = await getAllSchedulingSheetByDate(startDate, endDate)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_SCHEDULING_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_SCHEDULING_SHEET_FAIL, payload: err })
    }
}


export const deleteSchedulingSheetData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_SCHEDULING_SHEET })
        let { data: response } = await deleteSchedulingSheet(formData)
        if (response) {
            dispatch({
                type: DELETE_SCHEDULING_SHEET_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_SCHEDULING_SHEET_FAIL, payload: err })
    }
}
