import React, { Fragment } from 'react'
import RowCards from './shared/RowCards'
import StatCards from './shared/StatCards'
import Campaigns from './shared/Campaigns'
import { Grid, Card } from '@mui/material'
import StatCards2 from './shared/StatCards2'
import DoughnutChart from './shared/Doughnut'
import UpgradeCard from './shared/UpgradeCard'
import { styled, useTheme } from '@mui/system'
import TopSellingTable from './shared/TopSellingTable'
import ModifiedAreaChart from './shared/ModifiedAreaChart'
import LineChart from './shared/LineChart'

const ContentBox = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))

const Title = styled('span')(() => ({
    fontSize: '1rem',
    fontWeight: '500',
    textTransform: 'capitalize',
}))

const SubTitle = styled('span')(({ theme }) => ({
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
}))

const H4 = styled('h4')(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '500',
    marginBottom: '16px',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
}))

const Analytics = () => {
    const { palette } = useTheme()

    return (
        <Fragment>
            <ContentBox className="analytics">
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <StatCards />
                        <TopSellingTable />
                        {/* <StatCards2 /> */}
                        {/* <H4>Ongoing Orders</H4> */}
                        {/* <RowCards /> */}
                        <Card sx={{ px: 3, py: 2, mb: 3 }}>
                            <Title>Graph</Title>
                            <ModifiedAreaChart height="300px" />
                        </Card>
                        <Card sx={{ px: 3, py: 2, mb: 3 }}>
                            <Title>Line</Title>
                            <LineChart height="300px" />
                        </Card>
                    </Grid>

                    {/* <Grid item lg={4} md={4} sm={12} xs={12}> */}
                    <Card sx={{ px: 3, py: 2, mb: 3 }}>
                        <Title>Traffic Sources</Title>
                        <SubTitle>Last 30 days</SubTitle>
                        <DoughnutChart
                            height="300px"
                            color={[
                                palette.primary.dark,
                                palette.primary.main,
                                palette.primary.light,
                            ]}
                        />
                    </Card>
                    {/* <Card sx={{ px: 3, py: 2, mb: 3 }}>
                        <Title>Traffic Sources</Title>
                        <SubTitle>Last 30 days</SubTitle>
                        <DoughnutChart
                            height="300px"
                            color={[
                                palette.primary.dark,
                                palette.primary.main,
                                palette.primary.light,
                            ]}
                        />
                    </Card> */}

                    {/* <Card sx={{ px: 3, py: 2, mb: 3, ml: 3 }}>
                        <Title>Traffic Sources</Title>
                        <SubTitle>Last 30 days</SubTitle>
                        <DoughnutChart
                            height="300px"
                            color={[
                                palette.primary.dark,
                                palette.primary.main,
                                palette.primary.light,
                            ]}
                        />
                    </Card> */}
                    {/* <UpgradeCard /> */}
                    {/* <Campaigns /> */}
                    {/* </Grid> */}
                </Grid>
            </ContentBox>
        </Fragment>
    )
}

export default Analytics
