import React, { useEffect, useState } from 'react'
import { SimpleCard } from 'app/components'
import { styled } from '@mui/system'

import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Table, TableBody, TableRow, TableCell, TableContainer, TableHead, Paper, Typography, Box } from '@mui/material'

import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { deleteRawMaterial, getAllRawMaterial } from 'app/services/rawMaterial.service'
import { getAllProduct, deleteProduct } from 'app/services/product.service'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProductData, getAllProductData } from 'app/redux/actions/Product/product.action'
import { deleteUserData } from 'app/redux/actions/User/user.actions'
import { useNavigate } from 'react-router-dom'
import { getAllShiftDataByDates } from 'app/redux/actions/ShiftData/ShiftData.action'
import moment from 'moment'

export default function ViewReports() {
    const shiftDataArr = useSelector(state => state.shiftData.shiftDataArr);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const getUsers = async () => {
        try {
            dispatch(getAllShiftDataByDates(startDate, endDate))
        } catch (error) {
            toastError(error)
        }
    }
    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }






    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Products'}>
                <Typography sx={{ mt: 3 }}>Select Dates</Typography>
                <TextField
                    type="date"
                    onChange={e => setStartDate(e.target.value)}
                    value={startDate}
                    label="Start Date"
                    sx={{ width: 250, mt: 2 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    onChange={e => setEndDate(e.target.value)}
                    value={endDate}
                    label="End Date"
                    sx={{ width: 250, mt: 2, ml: 2 }}
                />

                <Button onClick={() => getUsers()} variant="contained" color="primary" sx={{ mt: 5, ml: 3 }}>
                    Generate Dates
                </Button>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Shift</TableCell>
                            <TableCell>Operator</TableCell>
                            <TableCell>Machine</TableCell>
                            <TableCell>Raw Material</TableCell>
                            <TableCell>Mould</TableCell>
                            <TableCell>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {shiftDataArr && shiftDataArr.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {`${new Date(row?.createdAt).getDate()}/${new Date(row?.createdAt).getMonth() + 1}/${new Date(row?.createdAt).getFullYear()}`}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    Shift {row?.shift}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.operatorName}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.machineObj?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.rawMaterialObj?.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row?.mouldName}
                                </TableCell>
                                <TableCell><Button variant='outlined' color='primary' onClick={() => { navigate(`/admin/viewReport/${row._id}`) }}>View Datails</Button></TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
