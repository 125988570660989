import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from '@mui/material'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllCategoryData } from 'app/redux/actions/MouldingCategories/mouldingCategory.action'
import { addProductData } from 'app/redux/actions/Product/product.action'
import {
    addProduct,
    getProductById,
    updateProduct,
} from 'app/services/product.service'
import { PRODUCT_TYPES } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function UpdateProduct() {
    const categoryArr = useSelector(
        (state) => state.mouldingCategories.categoryArr
    )
    const params = useParams()
    const dispatch = useDispatch()
    const [name, setName] = useState('')
    const [id, setId] = useState('')
    const [type, setType] = useState(PRODUCT_TYPES.IN_HOUSE)
    const [weight, setWeight] = useState(false)
    const [plating, setPlating] = useState(false)
    const [assembly, setAssembly] = useState(false)
    const [pdi, setPdi] = useState(0)
    const [mouldingCategoryId, setMouldingCategoryId] = useState('')

    useEffect(() => {
        getCategories()
    }, [])

    const getCategories = async () => {
        try {
            dispatch(getAllCategoryData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                id,
                type,
                weight,
                plating,
                pdi,
                assembly,
                mouldingCategoryId,
            }
            let { data: res } = await updateProduct(params.id, obj)
            if (res.success) {
                alert(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleGetProductById = async () => {
        try {
            let { data: res } = await getProductById(params.id)
            if (res.data) {
                setName(res.data.name)
                setId(res.data.id)
                setType(res.data.type)
                setPlating(res.data.plating)
                setPdi(res.data.pdi)
                setWeight(res.data.weight)
                setAssembly(res?.data?.assembly)
                if (res.data.type == PRODUCT_TYPES.IN_HOUSE) {
                    setMouldingCategoryId(res.data.mouldingCategoryId)
                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        handleGetProductById()
    }, [categoryArr])

    return (
        <Container>
            <SimpleCard title="Add Product">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <TextField
                        type="text"
                        name="id"
                        onChange={(e) => setId(e.target.value)}
                        value={id || ''}
                        label="Id"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    {/* 
          <TextField
            type="number"
            name="id"
            onChange={e => setCycleTime(e.target.valueAsNumber)}
            value={cycleTime || ''}
            label="Cycle Time"
            sx={{ width: '100%', mt: 2 }}
          /> */}

                    <TextField
                        type="number"
                        name="weight"
                        onChange={(e) => setWeight(e.target.value)}
                        value={weight || ''}
                        label="Weight"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <Box>
                        <FormControl sx={{ mt: 3 }}>
                            <FormLabel id="demo-radio-buttons-group-label">
                                Type
                            </FormLabel>
                            <RadioGroup
                                sx={{ mb: 2 }}
                                value={type || ''}
                                name="Type"
                                onChange={(e) => setType(e.target.value)}
                                row
                            >
                                <FormControlLabel
                                    value={PRODUCT_TYPES.IN_HOUSE}
                                    control={<Radio color="primary" />}
                                    label="In House"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={PRODUCT_TYPES.IMPORTED}
                                    control={<Radio color="primary" />}
                                    label="Imported"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {PRODUCT_TYPES.IN_HOUSE == type && (
                        <Box sx={{ mt: 3 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Moulding Category
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={mouldingCategoryId}
                                    label="Moulding Category"
                                    onChange={(e) =>
                                        setMouldingCategoryId(e.target.value)
                                    }
                                >
                                    {categoryArr?.map((el) => (
                                        <MenuItem value={el._id} key={el._id}>
                                            {el.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <FormGroup sx={{ mt: 3 }}>
                        <FormControlLabel
                            label="Plating"
                            control={
                                <Checkbox
                                    checked={plating ? true : false}
                                    onChange={(e) =>
                                        setPlating(e.target.checked)
                                    }
                                />
                            }
                        />
                        <FormControlLabel
                            label="PDI"
                            control={
                                <Checkbox
                                    checked={pdi ? true : false}
                                    onChange={(e) => setPdi(e.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label="Assembly"
                            control={
                                <Checkbox
                                    checked={assembly ? true : false}
                                    onChange={(e) =>
                                        setAssembly(e.target.checked)
                                    }
                                />
                            }
                        />
                    </FormGroup>

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        type="submit"
                    >
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
