import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllRawMaterial } from 'app/services/rawMaterial.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { addMRN } from 'app/services/mrn.service'
import { MaterialTestCertificateArr, MrnReasons } from 'app/utils/constant'
import { decodeJwt } from 'app/services/user.service'
import { getAllSupplier } from 'app/services/Supplier.service'
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function MaterialReceiptNote() {
    const dispatch = useDispatch()
    const productArr = useSelector((state) => state.product.productArr)

    const [cavityProductArr, setCavityProductArr] = useState([])

    const [name, setName] = useState('')
    const [mrnNumber, setMrnNumber] = useState('')
    const [mrnDate, setMRNDate] = useState(null)

    const [supplierName, setSupplierName] = useState('')
    const [qaJudgement, setQaJudgement] = useState('')
    const [storeComments, setStoreComments] = useState('')
    const [checkedBy, setCheckedBy] = useState('')
    const [approvedBy, setApprovedBy] = useState('')
    const [reason, setReason] = useState('')

    const [mainRawMaterialArr, setMainRawMaterialArr] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [partDialog, setPartDialog] = useState(false);
    const [rawMaterialArr, setRawMaterialArr] = useState([])

    const [supplierArr, setSupplierArr] = useState([]);

    const [selectedSupplierObj, setSelectedSupplierObj] = useState(null);





    const getSuppliers = async () => {
        try {
            let { data: res } = await getAllSupplier()
            if (res) {
                let tempArr = res.data;
                tempArr = tempArr.map(el => ({ ...el, label: el.name, value: el._id }))
                setSupplierArr([...tempArr])
            }
        } catch (error) {
            toastError(error)
        }
    }
    const [list, setList] = useState([])

    const handleSubmit = async () => {
        try {

            const decodedObj = await decodeJwt();

            let tempArr = list
            tempArr = tempArr
                .filter((el) => el.partNo != '')
                .map((el) => {
                    let tempObj = {
                        ...el,
                        partNo: el?.partNo,
                        partId: el?.partId,

                        materialTestCertificate: el?.materialTestCertificate?.value,

                    }
                    return tempObj
                })

            let obj = {
                mrnDate,
                createdById: decodedObj?.userId,
                mrnNumber,
                supplierName: selectedSupplierObj?.name,
                supplierId: selectedSupplierObj?._id,
                qaJudgement,
                storeComments,
                checkedBy,
                approvedBy,
                reason,
                materialList: tempArr,
            }
            console.log(obj, "SEND OBJ")
            const { data: res } = await addMRN(obj)
            if (res) {
                toastSuccess(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getRawMaterials = async () => {
        try {
            const { data: res } = await getAllRawMaterial()
            if (res) {
                let tempRawMaterialArr = res.data
                tempRawMaterialArr = tempRawMaterialArr.map((el) => ({ ...el, label: `${el.partNo} | ${el?.description} | ${el.name}`, value: el?._id }))
                console.log(tempRawMaterialArr)
                setRawMaterialArr([...tempRawMaterialArr])
                setMainRawMaterialArr([...tempRawMaterialArr])
                let tempArr = [
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                    { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, loose_qty: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
                ]
                setList([...tempArr])
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getRawMaterials()
        getSuppliers()
    }, [])

    const handleListChange = (value, index, field) => {
        console.log(value, 'VALUE')
        let tempArr = [...list]
        if (field == 'partNo') {
            tempArr[index]['partNo'] = value
            tempArr[index]['partDescription'] = value.description
        } else {
            tempArr[index][field] = value
        }
        setList(tempArr)
    }

    const handleRawMaterialSelect = (value) => {
        console.log(value, 'VALUE')
        let tempArr = [...list]
        tempArr[selectedIndex]['partNo'] = value?.partNo
        tempArr[selectedIndex]['partId'] = value?._id

        tempArr[selectedIndex]['partDescription'] = value.description
        setList(tempArr)
        setPartDialog(false)
    }

    const handleAddMore = () => {
        let tempArr = [...list]
        tempArr.push({ partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' })
        setList(tempArr)
    }

    const handleRawMaterialSearch = (value) => {
        let tempArr = [...mainRawMaterialArr];

        tempArr = tempArr.filter(el => el.partNo.toLowerCase().includes(value.toLowerCase()));
        setRawMaterialArr([...tempArr])
    }

    const handleSupplierSelect = (obj) => {
        console.log(obj)

        let tempArr = rawMaterialArr
        tempArr = rawMaterialArr.filter(el => obj?.rawMaterialArr?.some(elx => elx.rawMaterialId == el._id));
        setRawMaterialArr([...tempArr])
        setSelectedSupplierObj(obj)
        let tempDataArr = [
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
            { partNo: '', partDescription: '', partId: '', materialTestCertificate: 'No', lotNo: '', recievedQty: 0, loose_qty: 0, short_quantity: 0, purchaseOrderRefNo: '', balanceQty: 0, billNo: '' },
        ]
        setList(tempDataArr)
    }

    return (
        <Container>
            <SimpleCard title="Material Receipt Note">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <Grid container>
                        <Grid item xs={3}>
                            <div style={{ marginTop: 15 }}>MRN Number</div>
                            <TextField type="text" name="MRN Number" onChange={(e) => setMrnNumber(e.target.value)} value={mrnNumber || ''} label="MRN Number" sx={{ width: '90%', mt: 2 }} />
                        </Grid>
                        <Grid item xs={3}>
                            <div style={{ marginTop: 15 }}>Date</div>
                            <TextField type="date" name="MRN Date" onChange={(e) => setMRNDate(e.target.value)} value={mrnDate} InputLabelProps={{ shrink: true }} label="MRN Date" sx={{ width: '90%', mt: 2 }} />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ marginTop: 15, marginBottom: 15 }}>Supplier</div>
                            <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} placeholder="Supplier" value={selectedSupplierObj} options={supplierArr} onChange={(e) => handleSupplierSelect(e)} />
                        </Grid>
                    </Grid>
                    {selectedSupplierObj &&
                        <>



                            <TableContainer>
                                <Table sx={{ minWidth: 650, mt: 4, minHeight: 600 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">S.No</TableCell>
                                            <TableCell align="left">Part No</TableCell>
                                            <TableCell align="left">Part Description</TableCell>
                                            <TableCell align="left">Material Test</TableCell>
                                            <TableCell align="left">Lot No</TableCell>
                                            <TableCell align="left">Recieved Qty</TableCell>
                                            <TableCell align="left">Short  Qty</TableCell>
                                            <TableCell align="left">Loose  Qty</TableCell>
                                            <TableCell align="left">Purchase Order Ref. No.</TableCell>
                                            <TableCell align="left">Balance Qty.</TableCell>
                                            <TableCell align="left">Bill No.</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map((row, index) => (
                                            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" component="th" scope="row">
                                                    {index + 1}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row?.partNo ?
                                                        <Typography variant='subtitle2'>{row?.partNo}</Typography>

                                                        :
                                                        <Box onClick={() => { setPartDialog(true); setSelectedIndex(index) }} sx={{ cursor: 'pointer' }}>

                                                            <Typography variant='subtitle2'  >Select Part</Typography>
                                                        </Box>
                                                    }
                                                    {/* <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} placeholder="Part No..." options={rawMaterialArr} onChange={(e) => handleListChange(e, index, 'partNo')} /> */}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.partDescription} disabled type="text" onChange={(e) => handleListChange(e.target.value, index, 'partDescription')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Select options={MaterialTestCertificateArr} onChange={(e) => handleListChange(e, index, 'materialTestCertificate')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.lotNo} type="text" onChange={(e) => handleListChange(e.target.value, index, 'lotNo')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.recievedQty} type="text" onChange={(e) => handleListChange(e.target.value, index, 'recievedQty')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.short_quantity} type="text" onChange={(e) => handleListChange(e.target.value, index, 'short_quantity')} />
                                                </TableCell>

                                                <TableCell align="left">
                                                    <TextField value={row?.loose_qty} type="text" onChange={(e) => handleListChange(e.target.value, index, 'loose_qty')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.purchaseOrderRefNo} type="text" onChange={(e) => handleListChange(e.target.value, index, 'purchaseOrderRefNo')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.balanceQty} type="text" onChange={(e) => handleListChange(e.target.value, index, 'balanceQty')} />
                                                </TableCell>
                                                <TableCell align="left">
                                                    <TextField value={row?.billNo} type="text" onChange={(e) => handleListChange(e.target.value, index, 'billNo')} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Button variant="contained" color="primary" sx={{ mt: 2 }} type="button" onClick={() => handleAddMore()}>
                                Add More Parts
                            </Button>
                            <Grid container>

                                <Grid item xs={3}>
                                    <div style={{ marginTop: 15 }}>QA Judgement</div>
                                    <TextField type="text" disabled name="QA Judgement" onChange={(e) => setQaJudgement(e.target.value)} value={qaJudgement} label="QA judgement" sx={{ width: '90%', mt: 2 }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{ marginTop: 15 }}>Checked By</div>
                                    <TextField type="text" disabled name="Checked By" onChange={(e) => setCheckedBy(e.target.value)} value={checkedBy} label="Checked By" sx={{ width: '90%', mt: 2 }} />
                                </Grid>
                                <Grid item xs={3}>
                                    <div style={{ marginTop: 15 }}>Approved By</div>
                                    <TextField type="text" name="Approved By" onChange={(e) => setApprovedBy(e.target.value)} value={approvedBy} label="Approved By" sx={{ width: '90%', mt: 2 }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{ marginTop: 15 }}>Store comments</div>
                                    <TextField type="text" name="Store Comments" onChange={(e) => setStoreComments(e.target.value)} value={storeComments} I label="Store Comments" sx={{ width: '90%', mt: 2 }} />
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{ marginTop: 25 }}>Reason</div>
                                    <RadioGroup sx={{ mt: 2 }} value={reason || ''} name="Reason" onChange={(e) => setReason(e.target.value)} row>
                                        <FormControlLabel disabled value={MrnReasons.DAMAGED_PACKING} control={<Radio color="primary" />} label={MrnReasons.DAMAGED_PACKING} labelPlacement="end" />
                                        <FormControlLabel disabled value={MrnReasons.EXPIRY_MATERIAL} control={<Radio color="primary" />} label={MrnReasons.EXPIRY_MATERIAL} labelPlacement="end" />
                                        <FormControlLabel disabled value={MrnReasons.SHORT_QUANTITY} control={<Radio color="primary" />} label={MrnReasons.SHORT_QUANTITY} labelPlacement="end" />
                                        <FormControlLabel disabled value={MrnReasons.TESTING_NG} control={<Radio color="primary" />} label={MrnReasons.TESTING_NG} labelPlacement="end" />
                                        <FormControlLabel disabled value={MrnReasons.OTHERS} control={<Radio color="primary" />} label={MrnReasons.OTHERS} labelPlacement="end" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Button variant="contained" color="primary" sx={{ mt: 2 }} type="button" onClick={() => handleSubmit()}>
                                Submit
                            </Button>
                        </>
                    }
                </form>


                <Dialog open={partDialog} onClose={() => setPartDialog(false)} fullWidth>
                    <DialogTitle>Select Part Number</DialogTitle>
                    <DialogContent>
                        <TextField placeholder='Search' fullWidth onChange={(e) => handleRawMaterialSearch(e.target.value)} />
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="female"
                            name="radio-buttons-group"
                        >
                            {rawMaterialArr && rawMaterialArr.length > 0 && rawMaterialArr.map(el => {
                                return (

                                    <FormControlLabel onClick={() => handleRawMaterialSelect(el)} value={el.value} control={<Radio />} label={el.label} />
                                )
                            })}
                        </RadioGroup>

                    </DialogContent>

                </Dialog>
            </SimpleCard>
        </Container>
    )
}
