import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/AssemblyProduct`

export const addAssemblyProductToDB = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllAssemblyProduct = () => {
    return axios.get(`${url}`)
}
