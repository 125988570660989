import axios from '../../axios'
import { url as API_URL } from './url.service'

const url = `${API_URL}/Jig`

export const addJigs = async (obj) => {
    return axios.post(`${url}`, obj)
}
export const addJigsMaster = async (obj) => {
    return axios.post(`${url}/createJigMaster`, obj)
}

export const getAllJigs = () => {
    return axios.get(`${url}`)
}

export const getAllJigsData = () => {
    return axios.get(`${url}/AllData`)
}

export const getJigsById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteJigById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const updateJigById = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
