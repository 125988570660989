import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/MRN`

export const addMRN = async (obj) => {
    return await axios.post(`${url}/add`, obj)
}

export const getMRN = async () => {
    return await axios.get(`${url}/getAll`)
}

export const getMrnForQc = async () => {
    return await axios.get(`${url}/getMRNForQc`)
}

export const getById = async (id) => {
    return await axios.get(`${url}/getById/${id}`)
}

export const mrnQc = async (obj) => {
    return await axios.post(`${url}/mrnQc`, obj)
}

export const getQrDataToDownload = async (id) => {
    return await axios.get(`${url}/downloadQr/${id}`)
}
