import { addCategory, deleteCategory, getAllCategory } from '../../../services/category.service'

/////////////////////////////////////ADD USER
export const CATEGORY_ADD = 'CATEGORY_ADD'
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS'
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY'
export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS'
export const GET_ALL_CATEGORY_FAIL = 'GET_ALL_CATEGORY_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL'

export const addCategoryData = (formData) => async (dispatch) => {
    try {
        // console.log(formData, dispatch)
        dispatch({ type: CATEGORY_ADD })
        let { data: response } = await addCategory(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: CATEGORY_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: CATEGORY_ADD_FAIL, payload: err })
    }
}


export const getAllCategoryData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_CATEGORY })
        let { data: response } = await getAllCategory(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_CATEGORY_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_CATEGORY_FAIL, payload: err })
    }
}


export const deleteCategoryData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_CATEGORY })
        let { data: response } = await deleteCategory(formData)
        if (response) {
            dispatch({
                type: DELETE_CATEGORY_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_CATEGORY_FAIL, payload: err })
    }
}
