import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { logoutUser } from 'app/redux/actions/auth/auth.actions'

import { useDispatch } from 'react-redux'
export default function UnauthorizedPage() {
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(logoutUser())
    }
    return (

        <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
            <Paper sx={{ p: 5, display: 'grid', placeItems: 'center', }}>
                <Typography variant='h4' >You are not authorized to view this page</Typography>
                <Button onClick={() => handleLogout()} variant="outlined" sx={{ mt: 3 }} color='error' >Logout</Button>
            </Paper>
        </Box>
    )
}
