import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    DELETE_STAFF_ALLOCATION,
    DELETE_STAFF_ALLOCATION_FAIL,
    DELETE_STAFF_ALLOCATION_SUCCESS,
    GET_ALL_STAFF_ALLOCATION,
    GET_ALL_STAFF_ALLOCATION_FAIL,
    GET_ALL_STAFF_ALLOCATION_SUCCESS,
    STAFF_ALLOCATION_ADD,
    STAFF_ALLOCATION_ADD_FAIL,
    STAFF_ALLOCATION_ADD_SUCCESS,
} from '../../actions/StaffAllocation/StaffAllocation.action'

const initialState = {
    staffAllocationArr: null,
    loading: false,
    error: null,
}

export const staffAllocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case STAFF_ALLOCATION_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case STAFF_ALLOCATION_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case STAFF_ALLOCATION_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_STAFF_ALLOCATION:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_STAFF_ALLOCATION_SUCCESS:
            return {
                ...state,
                staffAllocationArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_STAFF_ALLOCATION_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_STAFF_ALLOCATION:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_STAFF_ALLOCATION_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                staffAllocationArr: state.staffAllocationArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_STAFF_ALLOCATION_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
