import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    PRODUCT_ADD,
    PRODUCT_ADD_FAIL,
    PRODUCT_ADD_SUCCESS,
    DELETE_PRODUCT,
    DELETE_PRODUCT_FAIL,
    DELETE_PRODUCT_SUCCESS,
    GET_ALL_PRODUCT,
    GET_ALL_PRODUCT_FAIL,
    GET_ALL_PRODUCT_SUCCESS,
    GET_ALL_MOULDING_PRODUCT,
    GET_ALL_MOULDING_PRODUCT_FAIL,
    GET_ALL_MOULDING_PRODUCT_SUCCESS,
    GET_ALL_PLATING_PRODUCT,
    GET_ALL_PLATING_PRODUCT_FAIL,
    GET_ALL_PLATING_PRODUCT_SUCCESS
} from '../../actions/Product/product.action'

const initialState = {
    productArr: [],
    platingProductsArr: [],
    mouldingProductsArr: [],
    loading: false,
    error: null,
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case PRODUCT_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case PRODUCT_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_PRODUCT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_PRODUCT_SUCCESS:
            return {
                ...state,
                productArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_PRODUCT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case GET_ALL_PLATING_PRODUCT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_PLATING_PRODUCT_SUCCESS:
            return {
                ...state,
                platingProductsArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_PLATING_PRODUCT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }








        case GET_ALL_MOULDING_PRODUCT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_MOULDING_PRODUCT_SUCCESS:
            return {
                ...state,
                mouldingProductsArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_MOULDING_PRODUCT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_PRODUCT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_PRODUCT_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                productArr: state.productArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_PRODUCT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
