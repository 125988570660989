import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    MACHINE_ADD,
    MACHINE_ADD_FAIL,
    MACHINE_ADD_SUCCESS,
    DELETE_MACHINE,
    DELETE_MACHINE_FAIL,
    DELETE_MACHINE_SUCCESS,
    GET_ALL_MACHINE,
    GET_ALL_MACHINE_FAIL,
    GET_ALL_MACHINE_SUCCESS,
} from '../../actions/Machines/machine.action'

const initialState = {
    machineArr: null,
    loading: false,
    error: null,
}

export const machineReducer = (state = initialState, action) => {
    switch (action.type) {
        case MACHINE_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case MACHINE_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case MACHINE_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_MACHINE:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_MACHINE_SUCCESS:
            return {
                ...state,
                machineArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_MACHINE_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_MACHINE:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_MACHINE_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                machineArr: state.machineArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_MACHINE_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
