import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/Store`

export const addStore = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllStore = () => {
    return axios.get(`${url}`)
}

export const deleteStore = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
