import axios from '../../axios'
import { url as API_URL } from './url.service'
import { decodeJwt, getJwt } from './user.service'

const url = `${API_URL}/mouldingCategory`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addCategory = async (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllCategory = () => {
    return axios.get(`${url}`)
}

export const getCategoryById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteCategory = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const updateCategory = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
