import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    Typography,
    Box,
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import ContentTable from 'app/components/Table/ContentTable'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import {
    addSchedulingSheetData,
    getAllSchedulingSheetDataByDates,
} from 'app/redux/actions/SchedulingSheet/Scheduling.action'
import { getAllSchedulingSheetByDate } from 'app/services/SchedulingSheet'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@mui/material/Modal'
import { getAllMouldingRequirementSheetByDateAll } from 'app/services/MouldingRequirementSheet'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid rgba(0,0,0,0.1)',
    boxShadow: 24,
    p: 4,
}

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))
export default function AddSchedulingSheet() {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const dispatch = useDispatch()
    const schedulingsheetArr = useSelector(
        (state) => state.schedulingSheet.schedulingSheetArr
    )
    const [selectedProductsArr, setSelectedProductsArr] = useState([])
    const [showProducts, setShowProducts] = useState(true)
    const productArr = useSelector((state) => state.product.productArr)
    const [localSchedulingSheet, setLocalSchedulingSheet] = useState([
        schedulingsheetArr,
    ])

    const [requirementArr, setRequirementArr] = useState([])

    const [localProductsArr, setLocalProductsArr] = useState([
        productArr.map((el) => {
            let obj = {
                datesArr: [],
                ...el,
            }
            return obj
        }),
    ])
    const [dateArr, setDateArr] = useState([])

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [datewiseDataArr, setDatewiseDataArr] = useState([])

    const getRequirements = async () => {
        try {
            const { data: res } = await getAllMouldingRequirementSheetByDateAll(
                startDate,
                endDate
            )
            if (res) {
                console.log(res.data)

                let DatesArr = getDates(new Date(startDate), new Date(endDate))
                console.log(selectedProductsArr, 'selectedProductsArr')
                let tempProductsArr = selectedProductsArr
                    .filter((elx) =>
                        res.data.some((eli) => eli.productId == elx)
                    )
                    .map((el) => {
                        let tempProductArr = res.data.filter(
                            (elz) => elz.productId == el
                        )
                        let obj = {
                            productId: el,
                            productName: tempProductArr[0]?.productname,
                            datesArr: tempProductArr.map((el) => ({
                                on: el.on,
                                quantity: el.quantity,
                            })),
                        }
                        return obj
                    })
                setDateArr([...DatesArr])
                // setLocalProductsArr([...tempProductsArr])

                console.log(tempProductsArr)
                setRequirementArr([...tempProductsArr])
                handleOpen()
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleSubmit = async () => {
        try {
            let finalArr = []

            let ScheduleArr = localProductsArr
                .filter((el) => el.changed)
                .map((el) => {
                    let arr = {}
                    arr = el.datesArr
                        .filter((el) => el.changed)
                        .map((ele) => {
                            let obj2 = {
                                productId: el._id,
                                on: ele.on,
                                quantity: ele.quantity,
                            }
                            return obj2
                        })
                    finalArr.push(...arr)
                })
            if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
                alert('Start Date cannot be greater than End Date')
            } else {
                let FinalObj = {
                    scheduleArr: finalArr,
                }
                let tempArr = localProductsArr.map((el) => {
                    let obj = {
                        ...el,
                        datesArr: el.datesArr.map((ele) => {
                            let obj2 = {
                                ...ele,
                                changed: false,
                            }
                            return obj2
                        }),
                        changed: false,
                    }
                    return obj
                })
                setLocalProductsArr(tempArr)
                dispatch(addSchedulingSheetData(FinalObj))
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    const getDates = (startDate, stopDate) => {
        var dateArray = new Array()
        var currentDate = startDate
        while (currentDate <= stopDate) {
            dateArray.push(new Date(currentDate))
            currentDate = currentDate.addDays(1)
        }
        return dateArray
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf())
        date.setDate(date.getDate() + days)
        return date
    }

    useEffect(() => {
        handleGenerateDatesArr()
        let tempArr = productArr.map((el) => el._id)
        setSelectedProductsArr([...tempArr])
    }, [productArr]) //

    useEffect(() => {
        if (schedulingsheetArr) {
            setLocalSchedulingSheet(schedulingsheetArr)
            let tempProductsArr = localProductsArr.map((el) => {
                let obj = {
                    ...el,
                }
                if (
                    schedulingsheetArr
                        .filter((elx) =>
                            selectedProductsArr.some(
                                (eli) => eli == elx.productId
                            )
                        )
                        .some((ele) => ele.productId == el._id)
                ) {
                    if (el.datesArr) {
                        el.datesArr = el.datesArr.map((ele) => {
                            if (ele) {
                                let Obj = schedulingsheetArr
                                    .filter((elx) =>
                                        selectedProductsArr.some(
                                            (eli) => eli == elx.productId
                                        )
                                    )
                                    .find(
                                        (elx) =>
                                            new Date(elx.on).getTime() ==
                                                new Date(ele.on).getTime() &&
                                            elx.productId == el._id
                                    )
                                if (Obj) ele.quantity = Obj.quantity
                            }
                            return ele
                        })
                        console.log(el, 'element')
                    }
                }
                return obj
            })
            console.log('user', tempProductsArr)
            setDatewiseDataArr([...tempProductsArr])
            setShowProducts(true)
        }
    }, [schedulingsheetArr])

    useEffect(() => {
        getDataOnInit()
    }, [])

    const getDataOnInit = async () => {
        try {
            dispatch(getAllProductData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleGenerateDatesArr = async () => {
        let DatesArr = getDates(new Date(startDate), new Date(endDate))

        let tempProductsArr = productArr
            .filter((elx) => selectedProductsArr.some((eli) => eli == elx._id))
            .map((el) => {
                let obj = {
                    ...el,
                    datesArr: DatesArr.map((ele) => {
                        let obj2 = {
                            on: ele,
                            quantity: 0,
                        }
                        return obj2
                    }),
                }
                return obj
            })
        setDateArr([...DatesArr])
        setLocalProductsArr([...tempProductsArr])
        dispatch(getAllSchedulingSheetDataByDates(startDate, endDate))
        // setShowProducts(true)
    }

    const handleSetQuantity = (productId, dateIndex, value) => {
        setLocalProductsArr((previousValue) => {
            // previousValue[productId].datesArr[dateId].quantity = value
            // previousValue[productId].changed = true
            // previousValue[productId].datesArr[dateId].changed = true
            // if (previousValue[productId].datesArr[dateId].quantity)
            //     previousValue[productId].datesArr[dateId].quantity = parseInt(previousValue[productId].datesArr[dateId].quantity)
            // return [...previousValue]
            return [
                ...previousValue.map((el) => {
                    if (el._id != productId) {
                        return el
                    } else {
                        let obj = { ...el, changed: true }
                        obj.datesArr = obj.datesArr.map((ele, i) =>
                            i == dateIndex
                                ? { ...ele, quantity: value, changed: true }
                                : ele
                        )
                        return obj
                    }
                }),
            ]
        })
    }

    const handleSelectProducts = (value) => {
        console.log(value, 'value')
        console.log(selectedProductsArr, 'selectedProductsArr')
        console.log(localProductsArr)
        let tempArr = datewiseDataArr.filter((el) =>
            value.some((ele) => ele == el._id)
        )
        console.log([...tempArr])
        setLocalProductsArr([...tempArr])
    }

    return (
        <Container>
            <SimpleCard title="Add Scheduling Sheet">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <Typography sx={{ mt: 3 }}>Select Dates</Typography>
                    <TextField
                        type="date"
                        onChange={(e) => setStartDate(e.target.value)}
                        value={startDate}
                        label="Start Date"
                        sx={{ width: 250, mt: 2 }}
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        onChange={(e) => setEndDate(e.target.value)}
                        value={endDate}
                        label="End Date"
                        sx={{ width: 250, mt: 2, ml: 2 }}
                    />

                    <Button
                        onClick={() => handleGenerateDatesArr()}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 5, ml: 3 }}
                    >
                        Generate Dates
                    </Button>
                    <Button
                        onClick={() => getRequirements()}
                        variant="contained"
                        color="primary"
                        sx={{ mt: 5, ml: 3 }}
                    >
                        View Requirements
                    </Button>

                    <Typography sx={{ mt: 3 }}>Select Products</Typography>
                    <Select
                        sx={{ mt: 3, minWidth: 150 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProductsArr}
                        multiple={true}
                        label="Products"
                        onChange={(e) => {
                            handleSelectProducts(e.target.value)
                            setSelectedProductsArr(e.target.value)
                            console.log(e.target.value, 'value')
                        }}
                    >
                        {productArr?.map((el) => (
                            <MenuItem value={el._id} key={el._id}>
                                {el.name}
                            </MenuItem>
                        ))}
                    </Select>

                    {showProducts == true && (
                        <>
                            <Typography sx={{ mt: 3 }}>
                                Select Products
                            </Typography>

                            <ContentTable
                                data={localProductsArr}
                                downloadInactive={true}
                                columns={[
                                    {
                                        name: 'Name',
                                        selector: (row) => row.name,
                                        sortable: true,
                                        searchable: true,
                                    },
                                    ...dateArr.map((el, i) => ({
                                        name: moment(el).format(
                                            'ddd DD-MMM-YY'
                                        ),
                                        cell: (row, index) => (
                                            <TextField
                                                type="number"
                                                onChange={(e) =>
                                                    handleSetQuantity(
                                                        row._id,
                                                        i,
                                                        e.target.value
                                                    )
                                                }
                                                value={row.datesArr[i].quantity}
                                            />
                                        ),
                                    })),
                                ]}
                            />

                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2 }}
                                type="submit"
                            >
                                Save
                            </Button>
                        </>
                    )}
                </form>
            </SimpleCard>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Requirement
                    </Typography>
                    <ContentTable
                        data={requirementArr}
                        downloadInactive={true}
                        columns={[
                            {
                                name: 'Name',
                                selector: (row) => row.productName,
                                sortable: true,
                                searchable: true,
                            },
                            ...dateArr.map((el, i) => ({
                                name: moment(el).format('ddd DD-MMM-YY'),
                                cell: (row, index) =>
                                    // <TextField
                                    //     type="number"
                                    //     onChange={(e) =>
                                    //         handleSetQuantity(
                                    //             row._id,
                                    //             i,
                                    //             e.target.value
                                    //         )
                                    //     }
                                    //     value={row.datesArr[i].quantity}
                                    // />
                                    row.datesArr[i].quantity,
                            })),
                        ]}
                    />

                    {/* {requirementArr && requirementArr.at.length>0} */}
                    <Typography
                        id="modal-modal-description"
                        sx={{ mt: 2 }}
                    ></Typography>
                </Box>
            </Modal>
        </Container>
    )
}
