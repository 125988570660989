import { styled } from '@mui/system'
import { useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export default function DownloadItQr() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const printRef = useRef()

    const [searchParams, setSearchParams] = useSearchParams()

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <>

            <div className="center_print" style={{ pageBreakAfter: 'always', height: "100%", display: "grid", placeItems: "center" }}>
                <div>
                    <Barcode height={50} value={searchParams.get("id")} width={3} />
                    {/* <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{ textAlign: 'center', color: "#000" }}>PART NO : {el?.partNo}</span>
                                        <br />
                                        <span style={{ textAlign: 'center', color: "#000" }}>LOT SIZE : {el?.displayLotQty}</span>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ textAlign: 'center', color: "#000" }}>PART DESC : {el.partDescription}</span>
                                        <br />
                                        <span style={{ textAlign: 'center', color: "#000" }}>MRN NO : {el?.mrnNo}</span>
                                    </Grid>
                                </Grid>
                                <span style={{ textAlign: 'center', color: "#000" }}>DATE : {moment(el?.createdAt).format("DD-MM-YYYY HH:mm a")}</span> */}
                </div>
            </div>

        </>
    )
}
