import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addJigsMaster } from 'app/services/Jigs.service'
import { getAllProduct } from 'app/services/product.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddJigsMaster() {

    const [name, setName] = useState('')
    const [startJigNumber, setStartJigNumber] = useState('');
    const [endJigNumber, setEndJigNumber] = useState('');
    const [productArr, setProductArr] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [qtyOrJigs, setQtyOrJigs] = useState('');
    const [qtyFlightBar, setQtyFlightBar] = useState('');
    const handleGetProducts = async () => {
        try {
            const { data: res } = await getAllProduct();
            if (res) {
                let tempArr = res.data.map(el => ({ label: el.name, value: el._id }))
                setProductArr([...tempArr])
            }
        }
        catch (error) {
            toastError(error)
        }
    }

    const handleSubmit = async () => {
        try {

            let obj = {
                name,
                startRange: startJigNumber,
                endRange: endJigNumber,
                qtyOrJigs,
                qtyFlightBar,
                productId: selectedProduct?.value,
                productName: selectedProduct?.label
            }
            const { data: res } = await addJigsMaster(obj)
            if (res) {
                toastSuccess(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleQtyChange = (value) => {
        let temp = parseInt(value);
        setQtyOrJigs(temp)
        setQtyFlightBar(temp * 3)
    }


    useEffect(() => {
        handleGetProducts()
    }, [])


    return (
        <Container>
            <SimpleCard title="Add Jigs Master">
                <form>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={6}>
                            <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />
                            <TextField type="text" name="name" onChange={(e) => setStartJigNumber(e.target.value)} value={startJigNumber || ''} label="Start Jig Number" sx={{ width: '100%', mt: 2 }} />
                            <TextField type="text" name="name" onChange={(e) => setEndJigNumber(e.target.value)} value={endJigNumber || ''} label="End Jig Number" sx={{ width: '100%', mt: 2 }} />
                            <TextField type="number" name="Qty" onChange={(e) => handleQtyChange(e.target.value)} value={qtyOrJigs || ''} label="Qty/Jigs" sx={{ width: '100%', mt: 2 }} />
                            <TextField type="number" name="Qty" disabled value={qtyFlightBar || ''} label="Qty Flight Bar" sx={{ width: '100%', mt: 2 }} />
                        </Grid>

                    </Grid>
                    <Typography variant='subtitle2' sx={{ mt: 2 }}>Product</Typography>
                    <Box sx={{ mt: 2 }}>

                        <Select placeholder="Please Select Part/Product" options={productArr} onChange={(e) => setSelectedProduct(e)} />
                    </Box>
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="button" onClick={() => handleSubmit()}>
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
