import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/Block`

export const addBlock = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllBlock = () => {
    return axios.get(`${url}`)
}

export const deleteBlock = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getByStoreId = async (id) => {
    return axios.get(`${url}/getByStoreId/${id}`)
}
