import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getById } from 'app/services/mrn.service'
import { MrnReasons } from 'app/utils/constant'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
export default function MrnDownload() {
    const [mrnObj, setMrnObj] = useState({})
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getAllMrns()
    }, [])

    const getAllMrns = async () => {
        try {

            let { data: res } = await getById(params.id)
            if (res) {

                console.log(res.data, "DATA")
                setMrnObj(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDownload = (id) => { }
    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={''}>
                <Table
                    sx={{
                        minWidth: 650,

                        '& .MuiTableCell-root': {
                            border: '2px solid #000',
                            textAlign: 'center',
                        },
                    }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell rowSpan={3} colSpan={2}>
                                LOGO
                            </TableCell>
                            <TableCell colSpan={5}> DEPTT. STORE</TableCell>
                            <TableCell colSpan={3}>DOC. NO.</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={5} rowSpan={2}>
                                TITLE : MATERIAL RECEIPT NOTE
                            </TableCell>
                            <TableCell colSpan={3}>Rev-</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}>EFF.DATE:-</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell colSpan={2}>Date : {mrnObj?.mrnDate}</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>

                            <TableCell></TableCell>
                            <TableCell colSpan={2}>MRN NO:-</TableCell>
                            <TableCell colSpan={3}>{mrnObj?.mrnNumber}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Part No</TableCell>

                            <TableCell>Part Name</TableCell>
                            <TableCell>Part Description</TableCell>

                            <TableCell>Material Test Certificate (YES/NO)</TableCell>
                            <TableCell>Lot / Batch No.</TableCell>
                            <TableCell>Recieved Qty</TableCell>
                            <TableCell>Purchase Order Ref. No.</TableCell>
                            <TableCell>Balance Qty</TableCell>
                            <TableCell>Party Challan/Bill No</TableCell>
                        </TableRow>
                        {/* /// LOOP HERE */}
                        {mrnObj &&
                            mrnObj?.materialList?.length &&
                            mrnObj?.materialList.map((el, i) => {
                                return (
                                    <TableRow>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{el?.partNo}</TableCell>
                                        <TableCell>{el?.partName}</TableCell>
                                        <TableCell>{el?.partDescription}</TableCell>

                                        <TableCell>{el?.materialTestCertificate}</TableCell>
                                        <TableCell>{el?.lotNo}</TableCell>
                                        <TableCell>{el?.recievedQty}</TableCell>
                                        <TableCell>{el?.purchaseOrderRefNo}</TableCell>
                                        <TableCell>{el?.balanceQty}</TableCell>
                                        <TableCell>{el?.billNo}</TableCell>
                                    </TableRow>
                                )
                            })}
                        <TableRow>
                            <TableCell rowSpan={2} colSpan={2}>
                                Supplier Name : {mrnObj?.supplierName}
                            </TableCell>
                            <TableCell colSpan={4}>QA Judgement : {mrnObj?.qaJudgement}</TableCell>
                            <TableCell colSpan={4}>Checked By : {mrnObj?.checkedBy}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2}>Store Comments : {mrnObj?.storeComments}</TableCell>
                            <TableCell colSpan={2}>Store Sign : {mrnObj?.createdByName} </TableCell>
                            <TableCell colSpan={4}>Approved By : {mrnObj?.approvedBy}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={10}>
                                Reason : <FormControlLabel control={<Checkbox checked={mrnObj?.reason == MrnReasons.DAMAGED_PACKING} />} label={MrnReasons.DAMAGED_PACKING} />{' '}
                                <FormControlLabel control={<Checkbox checked={mrnObj?.reason == MrnReasons.EXPIRY_MATERIAL} />} label={MrnReasons.EXPIRY_MATERIAL} />
                                <FormControlLabel control={<Checkbox checked={mrnObj?.reason == MrnReasons.SHORT_QUANTITY} />} label={MrnReasons.SHORT_QUANTITY} />
                                <FormControlLabel control={<Checkbox checked={mrnObj?.reason == MrnReasons.TESTING_NG} />} label={MrnReasons.TESTING_NG} />
                                <FormControlLabel control={<Checkbox checked={mrnObj?.reason == MrnReasons.OTHERS} />} label={MrnReasons.OTHERS} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
