import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { LinearProgress } from '@mui/material'
// import { withStyles } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search'
import FileDownloadIcon from '@mui/icons-material/FileDownload'

import RefreshIcon from '@mui/icons-material/Refresh'
import Table from './Table'

// const styles = (theme) => ({
//   paper: {
//     maxWidth: 'min(100% , 100vw)',
//     margin: theme.spacing(1),
//     flex:1,
//     // width:'100%',
//     // margin: 'auto',
//     overflow: 'auto',
//   },
//   searchBar: {
//     borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
//   },
//   searchInput: {
//     fontSize: theme.typography.fontSize,
//   },
//   block: {
//     display: 'block',
//   },
//   addUser: {
//     marginRight: theme.spacing(1),
//   },
//   contentWrapper: {
//     margin: theme.spacing(1),
//   },
// });

// const SearchFeild = styled((props) => (
//   <TextField InputProps={{ disableUnderline: true }} {...props} />
// ))(({ theme }) => ({
//   '& .MuiFilledInput-root': {
//     border: '1px solid #e2e2e1',
//     overflow: 'hidden',
//     borderRadius: 4,
//     backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
//     transition: theme.transitions.create([
//       'border-color',
//       'background-color',
//       'box-shadow',
//     ]),
//     '&:hover': {
//       backgroundColor: 'transparent',
//     },
//     '&.Mui-focused': {
//       backgroundColor: 'transparent',
//       boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }));

function ContentTable({
    reload,
    columns,
    data,
    title,
    loading,
    dataTableProps,
    extraButtonFn,
    downloadInactive,
    extraButtonText,
    ...props
}) {
    const [searchStr, setSearchStr] = useState('')
    const searchItems = columns?.filter((el) => el.searchable)
    ///export to excel
    function convertArrayOfObjectsToCSV(array) {
        let result

        const columnDelimiter = ','
        const lineDelimiter = '\n'
        const keys = Object.keys(array[0])
        console.log(keys)
        result = ''
        result += keys.join(columnDelimiter)
        result += lineDelimiter

        array.forEach((item) => {
            let ctr = 0
            keys.forEach((key) => {
                if (ctr > 0) result += columnDelimiter

                result += item[key]

                ctr++
            })
            result += lineDelimiter
        })

        return result
    }

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        if (array.length > 0) {
            const link = document.createElement('a')
            let csv = convertArrayOfObjectsToCSV(array)
            if (csv == null) return

            const filename = 'export.csv'

            if (!csv.match(/^data:text\/csv/i)) {
                csv = `data:text/csv;charset=utf-8,${csv}`
            }

            link.setAttribute('href', encodeURI(csv))
            link.setAttribute('download', filename)
            link.click()
        } else {
            alert('Collection Empty')
        }
    }
    function filter(el) {
        let chk = false
        if (!searchItems?.length) chk = true
        searchItems.forEach((elx) => {
            let val = elx?.selector(el)
            if (val) {
                if (typeof val == 'string' || typeof val == 'number') {
                    if (
                        `${val}`.toLowerCase().includes(searchStr.toLowerCase())
                    )
                        chk = true
                }
            }
        })
        return chk
    }
    return (
        <Paper
            sx={{
                maxWidth: 'min(100% , 100vw)',
                margin: (theme) => theme.spacing(1),
                flex: 1,
                // width:'100%',
                // margin: 'auto',
                overflow: 'auto',
            }}
            component="div"
        >
            <AppBar
                sx={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
                position="static"
                color="default"
                elevation={0}
            >
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <SearchIcon
                                sx={{ display: 'block' }}
                                color="inherit"
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                fullWidth
                                placeholder="Search"
                                InputProps={{
                                    disableUnderline: true,
                                    // className: classes.searchInput,
                                }}
                                value={searchStr}
                                variant="standard"
                                onChange={(e) => setSearchStr(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            {!!extraButtonFn && !!extraButtonText && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mr: 1 }}
                                    onClick={() => extraButtonFn()}
                                >
                                    {extraButtonText}
                                </Button>
                            )}
                            {reload && (
                                <Tooltip
                                    title="Reload"
                                    onClick={() => reload()}
                                >
                                    <IconButton>
                                        <RefreshIcon
                                            sx={{ display: 'block' }}
                                            color="inherit"
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        {
                            downloadInactive &&
                            <Grid item>
                                <Tooltip
                                    title="Download"
                                    onClick={() => downloadCSV(data)}
                                >
                                    <IconButton>
                                        <FileDownloadIcon
                                            sx={{ display: 'block' }}
                                            color="inherit"
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Toolbar>
            </AppBar>
            {loading && <LinearProgress />}
            <div
                sx={{
                    margin: (theme) => theme.spacing(1),
                }}
            >
                <Table
                    columns={columns}
                    data={data?.filter((el) => (searchStr ? filter(el) : true))}
                    title={title}
                    dataTableProps={dataTableProps}
                />
            </div>
        </Paper>
    )
}

export default ContentTable
