import { Button, TableBody, TableCell, TableHead, TableRow, Table, TextField, Grid, Box } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllDefects } from 'app/services/Defect.service'
import { getById, mrnQc } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import Select from 'react-select'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { decodeJwt } from 'app/services/user.service'
export default function MrnQcDetails() {
    const [mrnObj, setMrnObj] = useState(null)
    const [materialListArr, setMaterialListArr] = useState([])
    const params = useParams()
    const [defectsArr, setDefectsArr] = useState([])
    const [selectedReason, setSelectedReason] = useState({});
    const [finalJudgement, setFinalJudgement] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getAllMrns()
        getDefects()
    }, [])

    const getAllMrns = async () => {
        try {
            let { data: res } = await getById(params.id)
            if (res) {
                setMrnObj(res?.data)
                let tempArr = res.data.materialList
                tempArr = tempArr.map((el) => ({ ...el, okQty: 0, ngQty: 0, reasonArr: [] }))
                setMaterialListArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const getDefects = async () => {
        try {
            const { data: res } = await getAllDefects()
            if (res) {
                let tempArr = res.data
                tempArr = tempArr.map((el) => ({ ...el, label: el.name, value: el._id }))
                setDefectsArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    const handleQtyChange = (value, index, key) => {
        let tempArr = [...materialListArr]

        tempArr[index][key] = value
        if (parseInt(tempArr[index].okQty) + parseInt(tempArr[index].ngQty) > parseInt(tempArr[index].recievedQty)) {
            alert('Sum of ok Qty and ng quantity must be less than recieved Qty')
        } else {
            setMaterialListArr(tempArr)
        }
    }

    const handleDefectChange = (value, index) => {
        let tempArr = [...materialListArr]

        tempArr[index].reasonArr = value

        setMaterialListArr(tempArr)
    }

    const handleSubmit = async () => {
        try {
            const decodedObj = await decodeJwt();

            let obj = {
                ...mrnObj,
                mrnId: mrnObj?._id,
                isQcDone: true,
                reason: selectedReason?.label,
                checkedById: decodedObj?.userId,
                qaJudgement: finalJudgement,
                materialList: materialListArr.map((el) => ({ ...el, reasonArr: el.reasonArr.map((ele) => ele.label) })),
            }
            delete obj._id

            if (obj.materialList.every((el) => parseInt(el.recievedQty) == parseInt(el.ngQty) + parseInt(el.okQty))) {
                const { data: res } = await mrnQc(obj)
                if (res) {
                    alert(res.message)
                    navigate(-1)
                }
            } else {
                alert('Quantity Mismatch please check recieved Qty , Ok QTY and NG QTY')
            }

            // console.log(obj)
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Box sx={{ m: 5 }}>
            <SimpleCard title={'Quality Check'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Part No</TableCell>
                            <TableCell>Part Decription</TableCell>
                            <TableCell>Lot No</TableCell>

                            <TableCell>Medical Test Certificate</TableCell>
                            <TableCell>Recieved Qty.</TableCell>
                            <TableCell>Ok Qty.</TableCell>
                            <TableCell>Rejected Qty.</TableCell>
                            <TableCell>Rejected Reasons</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {materialListArr &&
                            materialListArr.length > 0 &&
                            materialListArr.map((el, i) => {
                                return (
                                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell>{el?.partNo}</TableCell>
                                        <TableCell>{el?.partDescription}</TableCell>
                                        <TableCell>{el?.lotNo}</TableCell>
                                        <TableCell>{el?.materialTestCertificate}</TableCell>
                                        <TableCell>{el?.recievedQty}</TableCell>
                                        <TableCell>
                                            <TextField placeholder="Ok Qty" value={el?.okQty} onChange={(e) => handleQtyChange(e.target.value, i, 'okQty')} />
                                        </TableCell>
                                        <TableCell>
                                            <TextField placeholder="Rejected Qty" value={el?.ngQty} onChange={(e) => handleQtyChange(e.target.value, i, 'ngQty')} />
                                        </TableCell>
                                        <TableCell>
                                            <Select placeholder={'Reasons.....'} isMulti options={defectsArr} value={el?.reasonArr} onChange={(e) => handleDefectChange(e, i)} />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>

                </Table>
            </SimpleCard>
            <Grid container sx={{ mt: 2 }}>
                <Grid item md={4}>
                    <TextField placeholder='Judgement' value={finalJudgement} onChange={(e) => setFinalJudgement(e.target.value)} fullWidth />

                </Grid>
                <Grid item md={2} sx={{ ml: 2 }}>
                    <Select placeholder={'Reasons.....'} onChange={(e) => setSelectedReason(e)} options={defectsArr} />
                </Grid>
                <Grid item md={1} sx={{ ml: 2 }}>
                    <Button onClick={() => handleSubmit()} type="button" variant="outlined">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}
