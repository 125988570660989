import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/schedulingSheet`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addSchedulingSheet = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllSchedulingSheet = () => {
    return axios.get(`${url}/`)
}

export const getAllSchedulingSheetByDate = (startDate, endDate) => {
    return axios.get(`${url}/getbydates/${startDate}/${endDate}`)
}

export const deleteSchedulingSheet = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
