import AuthGuard from 'app/auth/AuthGuard'
import AdminRoutes from 'app/views/admin/AdminRoutes'
import NotFound from 'app/views/sessions/NotFound'
import chartsRoute from 'app/views/charts/ChartsRoute'
import materialRoutes from 'app/views/material-kit/MaterialRoutes'
import dashboardRoutes from 'app/views/dashboard/DashboardRoutes'
import sessionRoutes from 'app/views/sessions/SessionRoutes'
import MatxLayout from '../components/MatxLayout/MatxLayout'
import { Navigate } from 'react-router-dom'
import { Roles } from '../../Roles'
export const AllPages = () => {
    const all_routes = [
        {
            element: (
                <AuthGuard
                    role={[
                        Roles.admin,
                        Roles.manager,
                        Roles.SUB_ADMIN,
                        Roles.STORE,
                        Roles.MOULDING,
                        Roles.QC_PDI,
                        Roles.STORE_EXE

                    ]}
                >
                    <MatxLayout />
                </AuthGuard>
            ),
            children: [
                ...dashboardRoutes,
                ...chartsRoute,
                ...materialRoutes,
                ...AdminRoutes,
            ],
        },
        ...sessionRoutes,
        {
            path: '/',
            element: <Navigate to="/dashboard/default" />,
        },
        {
            path: '*',
            element: <NotFound />,
        },
    ]

    return all_routes
}
