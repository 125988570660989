import { Button, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addDefects } from 'app/services/Defect.service'
import { DEFECT_CATEGORY } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddDefects() {
    const dispatch = useDispatch()

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [defectCategory, setDefectCategory] = useState('')
    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                description,
                defectCategory,
            }
            const { data: res } = await addDefects(obj)
            if (res) {
                toastSuccess(res.message)
            }
        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Add Defects">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="Description" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} />
                    <RadioGroup sx={{ mb: 2 }} value={defectCategory || ''} name="Role" onChange={(e) => setDefectCategory(e.target.value)} row>
                        <FormControlLabel value={DEFECT_CATEGORY.Moulding} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Moulding} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.Plating} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Plating} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.Assembly} control={<Radio color="primary" />} label={DEFECT_CATEGORY.Assembly} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.QC} control={<Radio color="primary" />} label={DEFECT_CATEGORY.QC} labelPlacement="end" />
                        <FormControlLabel value={DEFECT_CATEGORY.MRN} control={<Radio color="primary" />} label={DEFECT_CATEGORY.MRN} labelPlacement="end" />
                    </RadioGroup>

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
