import { Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addCategoryData } from 'app/redux/actions/MouldingCategories/mouldingCategory.action'
import { getCategoryById, updateCategory } from 'app/services/category.service'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))






export default function UpdateCategory() {
    const dispatch = useDispatch();
    const [name, setName] = useState('')
    const params = useParams();

    const handleSubmit = async () => {
        try {
            if (name != "") {

                let obj = {
                    name,
                }
                let { data: res } = await updateCategory(params.id, obj)
                {
                    alert(res.message)
                }

                // dispatch(addCategoryData(obj))
                // const res = await addCategory(obj)

                // if (res?.data?.message)
                //   // toast.success(res.data.message)
                //   alert(res.data.message)
            }
            else {
                alert("Please enter name")
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            // toast.success(error?.response?.data?.message)
            else
                alert(error.message)
            // toast.error(error.message)
        }
    }



    const handleGetById = async () => {
        try {
            let { data: res } = await getCategoryById(params.id)
            if (res.data) {
                setName(res.data.name)
            }
        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            // toast.success(error?.response?.data?.message)
            else
                alert(error.message)
            // toast.error(error.message)
        }
    }



    useEffect(() => {
        handleGetById()
    }, [])


    return (
        <Container>
            <SimpleCard title="Update Category">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <TextField
                        type="text"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
