import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductionWaste } from 'app/services/shiftData.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'

export default function ViewWaste() {
    const [rawMaterialRequestArr, setRawMaterialRequestArr] = useState([])




    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = async () => {
        try {
            let { data: res } = await getAllProductionWaste()
            if (res) {
                console.log(res.data)
                setRawMaterialRequestArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }




    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))




    return (
        <Container>
            <SimpleCard title={'View Waste'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Waste Type</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Shift</TableCell>
                            <TableCell>Machine</TableCell>
                            <TableCell>Raw Material</TableCell>
                            <TableCell>Operator</TableCell>



                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rawMaterialRequestArr &&
                            rawMaterialRequestArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row?.wasteType}</TableCell>
                                    <TableCell>{row?.quantity}</TableCell>
                                    <TableCell>
                                        {row?.unit}
                                    </TableCell>
                                    <TableCell>
                                        {row?.shiftData?.shift}
                                    </TableCell>
                                    <TableCell>
                                        {row?.machineObj?.name}
                                    </TableCell>
                                    <TableCell>
                                        {row?.rawMaterialUsedArr.reduce((acc, el) => acc + el + ",", '')}
                                    </TableCell>
                                    <TableCell>
                                        {row?.shiftData?.operatorName}
                                    </TableCell>


                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>


        </Container>
    )
}
