import axios from '../../axios'
import { url as apiUrl } from './url.service'
import { getJwt } from './user.service'
const url = `${apiUrl}/rawMaterial`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addRawMaterial = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllRawMaterial = () => {
    return axios.get(`${url}`)
}
export const getRawMaterialById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteRawMaterial = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const updateRawMaterial = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}


export const getRawMaterialRequest = async () => {
    return axios.get(`${url}/getRawMaterialRequest`)
}

export const acceptRawMaterial = async (obj) => {
    return axios.post(`${url}/acceptRawMaterialRequest`, obj)
}

export const rejectRawMaterial = async (obj) => {
    return axios.post(`${url}/rejectRawMaterialRequest`, obj)
}

