import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    CLIENT_ADD,
    CLIENT_ADD_FAIL,
    CLIENT_ADD_SUCCESS,
    DELETE_CLIENT,
    DELETE_CLIENT_FAIL,
    DELETE_CLIENT_SUCCESS,
    GET_ALL_CLIENT,
    GET_ALL_CLIENT_FAIL,
    GET_ALL_CLIENT_SUCCESS,
} from '../../actions/Client/client.action'

const initialState = {
    clientArr: [],
    loading: false,
    error: null,
}

export const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLIENT_ADD:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case CLIENT_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case CLIENT_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_CLIENT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_CLIENT_SUCCESS:
            return {
                ...state,
                clientArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_CLIENT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_CLIENT:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_CLIENT_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                clientArr: state.clientArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_CLIENT_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
