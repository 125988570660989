import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material'

import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { getClientById, updateClient } from 'app/services/client.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Select from 'react-select'
const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))


export default function UpdateClient() {
    const dispatch = useDispatch()

    const productArr = useSelector(state => state.product.productArr)
    const [displayProductArr, setDisplayProductArr] = useState([]);
    const [selectedProductArr, setSelectedProductArr] = useState([])
    const [name, setName] = useState('')
    const params = useParams()


    const toggleSelectProduct = (id) => {
        setSelectedProductArr(prev => {
            if (prev.some(el => el == id)) {
                return prev.filter(el => el != id)
            }
            else {
                return [...prev, id]
            }
        })
    }


    const isChecked = (id) => {
        return selectedProductArr.some(el => el == id)
    }



    useEffect(() => {
        dispatch(getAllProductData())
    }, [])

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                productIdArr: selectedProductArr.map(el => ({ productId: el }))
            }
            let { data: res } = await updateClient(params.id, obj);
            if (res.success) {
                alert(res.message)
            }

        } catch (error) {
            console.error(error)
            toastError(error)
            // alert(error)
        }
    }


    useEffect(() => {
        if (productArr && productArr.length > 0) {
            setDisplayProductArr([...productArr.map(el => ({ ...el, label: el.name, value: el._id }))])
            handleGetClientData()
        }


    }, [productArr])

    const handleGetClientData = async () => {
        try {

            let { data: res } = await getClientById(params.id);
            if (res.data) {
                console.log(res.data, "getting client")
                setName(res.data.name)
                if (res.data.productIdArr?.length) {
                    let tempArr = res.data.productIdArr;
                    let arr = []
                    for (let el of productArr) {
                        if (tempArr.some(ele => ele.productId == el._id)) {
                            arr.push(el)
                        }
                    }

                    setSelectedProductArr([...arr.map(el => ({ ...el, label: el.name, value: el._id }))])
                }
            }
        } catch (error) {
            console.error(error)
            toastError(error)
            // alert(error)
        }
    }



    return (
        <Container>
            <SimpleCard title="Update Client">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <TextField
                        type="text"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />


                    <Typography sx={{ mt: 2 }} >Products List </Typography>

                    <Select options={displayProductArr} value={selectedProductArr} isMulti onChange={(e) => setSelectedProductArr(e)} />

                    {/* {!!productArr && productArr.map(el => (
                        <FormGroup key={el._id} >
                            <FormControlLabel control={<Checkbox
                                checked={isChecked(el._id)}
                                onChange={() => toggleSelectProduct(el._id)}
                            />} label={el.name} />
                        </FormGroup>
                    ))
                    } */}




                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}