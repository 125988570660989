import { Button, FormControlLabel, TextField } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { getMouldById, UpdateMouldById } from 'app/services/mould.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function UpdateMould() {
    const params = useParams()
    const dispatch = useDispatch()
    const productArr = useSelector((state) => state.product.productArr)

    const [localProductsArr, setLocalProductsArr] = useState([
        productArr &&
            productArr.map((el) => {
                let obj = {
                    ...el,
                    checked: false,
                }
                return obj
            }),
    ])
    const [name, setName] = useState('')
    const [mouldNumber, setMouldNumber] = useState('')
    const [cycleTime, setCycleTime] = useState(0)

    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                mouldNumber: mouldNumber,
                productArr: localProductsArr
                    .filter((el) => el.checked == true)
                    .map((el) => {
                        return { productId: el._id }
                    }),
                cycleTime: parseInt(cycleTime),
            }
            let { data: res } = await UpdateMouldById(params.id, obj)
            console.log(res)
            if (res) {
                alert(res.message)
                getAllProducts()
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    useEffect(() => {
        setLocalProductsArr(
            productArr &&
                productArr.map((el) => {
                    let obj = {
                        ...el,
                        checked: false,
                    }
                    return obj
                })
        )
        if (productArr && productArr.length) {
            getMouldingDataById(productArr)
        }
    }, [productArr]) // s

    useEffect(() => {
        getAllProducts()
    }, [])

    const getAllProducts = async () => {
        try {
            dispatch(getAllProductData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleProductSelect = (index) => {
        let tempArr = localProductsArr.map((el, i) => {
            if (i == index) {
                el.checked = !el.checked
            }
            return el
        })
        console.log(tempArr)
        setLocalProductsArr([...tempArr])
    }

    const getMouldingDataById = async (productLocalarr) => {
        try {
            console.log(params.id)
            let { data: res } = await getMouldById(params.id)
            console.log(res)
            if (res.data) {
                console.log(productLocalarr, 'localProductsArr')
                setName(res?.data?.name)
                setMouldNumber(res?.data?.mouldNumber)
                setCycleTime(res?.data?.cycleTime)
                if (productArr) {
                    let tempArr = productArr.map((el) => {
                        if (
                            res?.data?.productArr?.some(
                                (ele) => ele?.productId == el?._id
                            )
                        ) {
                            el.checked = true
                        } else {
                            el.checked = false
                        }
                        return el
                    })
                    setLocalProductsArr(tempArr)
                }
            }
        } catch (error) {
            console.error(error)
            toastError(error)
        }
    }

    // useEffect(() => {
    //     // getMouldingDataById()
    // }, [])

    return (
        <Container>
            <SimpleCard title="Update Mould">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <div style={{ marginTop: 15 }}>Mould name</div>
                    <TextField
                        type="text"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <TextField
                        type="text"
                        name="Mould Number"
                        onChange={(e) => setMouldNumber(e.target.value)}
                        value={mouldNumber || ''}
                        label="Mould Number"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <div style={{ marginTop: 15 }}>Select Products</div>
                    <div className="row">
                        <div className="col-12">
                            {localProductsArr &&
                                localProductsArr.map((el, index) => {
                                    return (
                                        <FormControlLabel
                                            key={`${new Date().getTime()}${index}`}
                                            control={
                                                <Checkbox
                                                    onChange={() =>
                                                        handleProductSelect(
                                                            index
                                                        )
                                                    }
                                                    checked={el?.checked}
                                                />
                                            }
                                            label={el?.name}
                                        />
                                    )
                                })}
                        </div>
                    </div>
                    <div style={{ marginTop: 15 }}>Cycle Type</div>
                    <TextField
                        type="text"
                        name="name"
                        onChange={(e) => setCycleTime(e.target.value)}
                        value={cycleTime || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        type="submit"
                    >
                        Update Mould
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
