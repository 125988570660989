import { addProduct, deleteProduct, getAllMouldingProducts, getAllPlatingProducts, getAllProduct } from '../../../services/product.service'

/////////////////////////////////////ADD USER
export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCT_ADD_SUCCESS = 'PRODUCT_ADD_SUCCESS'
export const PRODUCT_ADD_FAIL = 'PRODUCT_ADD_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_PRODUCT = 'GET_ALL_PRODUCT'
export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS'
export const GET_ALL_PRODUCT_FAIL = 'GET_ALL_PRODUCT_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_MOULDING_PRODUCT = 'GET_ALL_MOULDING_PRODUCT'
export const GET_ALL_MOULDING_PRODUCT_SUCCESS = 'GET_ALL_MOULDING_PRODUCT_SUCCESS'
export const GET_ALL_MOULDING_PRODUCT_FAIL = 'GET_ALL_MOULDING_PRODUCT_FAIL'


/////////////////////////////////////GET USER
export const GET_ALL_PLATING_PRODUCT = 'GET_ALL_PLATING_PRODUCT'
export const GET_ALL_PLATING_PRODUCT_SUCCESS = 'GET_ALL_PLATING_PRODUCT_SUCCESS'
export const GET_ALL_PLATING_PRODUCT_FAIL = 'GET_ALL_PLATING_PRODUCT_FAIL'

/////////////////////////////////////DELETE USER
export const DELETE_PRODUCT = 'DELETE_PRODUCT'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL'

export const addProductData = (formData) => async (dispatch) => {
    try {
        // console.log(formData, dispatch)
        dispatch({ type: PRODUCT_ADD })
        let { data: response } = await addProduct(formData)
        if (response) {
            console.log(response)
            dispatch({
                type: PRODUCT_ADD_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: PRODUCT_ADD_FAIL, payload: err })
    }
}


export const getAllProductData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_PRODUCT })
        let { data: response } = await getAllProduct(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_PRODUCT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_PRODUCT_FAIL, payload: err })
    }
}




export const getAllMouldingProductData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_MOULDING_PRODUCT })
        let { data: response } = await getAllMouldingProducts(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_MOULDING_PRODUCT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_MOULDING_PRODUCT_FAIL, payload: err })
    }
}


export const getAllPlatingProductData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_PLATING_PRODUCT })
        let { data: response } = await getAllPlatingProducts(formData)
        console.log(response)
        if (response) {
            dispatch({
                type: GET_ALL_PLATING_PRODUCT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_PLATING_PRODUCT_FAIL, payload: err })
    }
}


export const deleteProductData = (formData) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PRODUCT })
        let { data: response } = await deleteProduct(formData)
        if (response) {
            dispatch({
                type: DELETE_PRODUCT_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: DELETE_PRODUCT_FAIL, payload: err })
    }
}
