import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getMRN, getMrnForQc } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ViewMrnQc() {
    const [mrnArr, setMrnArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getAllMrns()
    }, [])

    const getAllMrns = async () => {
        try {
            let { data: res } = await getMrnForQc()
            if (res) {
                setMrnArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDownload = (id) => {
        navigate(`/mrn-qc/${id}`)
    }
    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'MRN Qc'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>MRN Number</TableCell>
                            <TableCell>MRN Date</TableCell>
                            <TableCell>Supplier</TableCell>
                            <TableCell>QA Judgement</TableCell>
                            <TableCell>Action</TableCell>
                            <TableCell>QR Code</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mrnArr &&
                            mrnArr.map((row, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row?.mrnNumber}</TableCell>
                                    <TableCell>{row?.mrnDate}</TableCell>
                                    <TableCell>{row?.supplierName}</TableCell>
                                    <TableCell>{row?.qaJudgement}</TableCell>

                                    <TableCell>
                                        {!row?.isQcDone && (
                                            <Button variant="outlined" color="error" onClick={() => handleDownload(row._id)}>
                                                Start
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" color="primary" onClick={() => navigate(`/DownloadQr/${row?._id}`)}>
                                            Download
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
