import axios from '../../axios'
import { url as API_URL } from './url.service'
const url = `${API_URL}/Bin`

export const addBin = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllBin = () => {
    return axios.get(`${url}`)
}

export const deleteBin = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const getBinByRackId = async (id) => {
    return axios.get(`${url}/getByRackId/${id}`)
}
