import React, { useEffect, useState } from 'react'
import { Breadcrumb, SimpleCard } from 'app/components'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import { Grid, TextField, Button, Select, FormControl, MenuItem, InputLabel, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, FormLabel, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { USERS } from 'app/utils/constant'
import { addUser } from 'app/services/user.service'
import { toast } from 'react-toastify'
import { addCategory } from 'app/services/category.service'
import { addMachine, getMachineById, updateMachine } from 'app/services/machine.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { getAllProduct } from 'app/services/product.service'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProductData } from 'app/redux/actions/Product/product.action'
import { addMachineData } from 'app/redux/actions/Machines/machine.action'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))






const UpdateMachine = () => {
    const mainproductArr = useSelector(state => state.product.productArr);
    const dispatch = useDispatch()
    const params = useParams()
    const [name, setName] = useState('')
    const [loadingCapacity, setLoadingCapacity] = useState(1)
    const [productArr, setProductArr] = useState([])


    useEffect(() => {
        getProducts()
    }, [])
    useEffect(() => {

        handleGetMachineData(mainproductArr)

        setProductArr(mainproductArr)
    }, [mainproductArr])

    const getProducts = async () => {
        try {
            dispatch(getAllProductData())
        } catch (error) {
            toastError(error)
        }
    }

    const handleCheckboxChange = (id) => {
        setProductArr((prev) => prev.map(el => (el._id == id ? { ...el, checked: !el.checked } : el)))
    }


    const handleSubmit = async () => {
        try {
            let obj = {
                name,
                loadingCapacity: loadingCapacity,
                productIdArr: productArr.filter(el => el.checked).map(el => ({ productId: el._id }))
            }
            let { data: res } = await updateMachine(params.id, obj)
            if (res.success) {
                alert(res.message)
            }


            // dispatch(addMachineData(obj))

        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            // toast.success(error?.response?.data?.message)
            else
                alert(error?.message)
            // toast.error(error.message)
        }
    }



    const handleGetMachineData = async (mainproductArr) => {
        try {
            let { data: res } = await getMachineById(params.id)
            if (res.data) {
                console.log(res.data, "machine Ress")
                setName(res.data.name)
                setLoadingCapacity(res.data.loadingCapacity)
                let tempArr = mainproductArr.map(el => {
                    if (res.data.productIdArr.some(ele => ele.productId == el._id)) {
                        el.checked = true
                    }
                    else {
                        el.checked = false
                    }
                    return el
                })
                setProductArr([...tempArr])
                // console.log(productArr)
            }

        } catch (error) {
            console.error(error)
            if (error?.response?.data?.message)
                alert(error?.response?.data?.message)
            // toast.success(error?.response?.data?.message)
            else
                alert(error?.message)
            // toast.error(error.message)
        }
    }



    return (
        <Container>
            <SimpleCard title="Update Machines">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <TextField
                        type="text"
                        name="name"
                        onChange={e => setName(e.target.value)}
                        value={name || ''}
                        label="Name"
                        sx={{ width: '100%', mt: 2 }}
                    />
                    <TextField
                        type="number"
                        name="capacity"
                        onChange={e => setLoadingCapacity(e.target.valueAsNumber)}
                        value={loadingCapacity || ''}
                        label="Loading Capacity"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <FormGroup sx={{ mt: 3 }} >
                        <FormLabel>Products</FormLabel>
                        {
                            productArr && productArr.map(el => {
                                return (
                                    <>
                                        <FormControlLabel
                                            key={el._id}
                                            label={el.name}
                                            control={
                                                <Checkbox
                                                    checked={el.checked ? true : false}
                                                    onChange={(e) => handleCheckboxChange(el._id)}
                                                />
                                            }
                                        />
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nozzel Temp</TableCell>
                                                    <TableCell>Temp Z1</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                <TableRow

                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >


                                                    <TableCell>
                                                        <TextField type="text" />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField type="text" />
                                                    </TableCell>
                                                </TableRow>

                                            </TableBody>
                                        </Table>
                                    </>

                                )
                            }
                            )
                        }
                    </FormGroup>




                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Update
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}

export default UpdateMachine