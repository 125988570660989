import { Grid } from '@mui/material'
import { styled } from '@mui/system'
import { getQrDataToDownload } from 'app/services/mrn.service'
import { toastError } from 'app/utils/toastUtils'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import Barcode from 'react-barcode'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

export default function DownloadQr() {
    const [qrArr, setQrArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const printRef = useRef()
    useEffect(() => {
        getAllMrns()
    }, [])

    const getAllMrns = async () => {
        try {
            let { data: res } = await getQrDataToDownload(params.id)
            if (res) {
                // let tempArr = [res.data[0]]
                console.log(res.data)
                setQrArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <>

            {qrArr &&
                qrArr.length > 0 &&
                qrArr.map((el, i) => {
                    return (
                        <div key={i} className="center_print" style={{ pageBreakAfter: 'always', height: "100%", display: "grid", placeItems: "center" }}>
                            <div>
                                <Barcode height={50} value={el?.barcodeId} key={i} width={3} />
                                <Grid container>
                                    <Grid item xs={6}>
                                        <span style={{ textAlign: 'center', color: "#000" }}>PART NO : {el?.partNo}</span>
                                        <br />
                                        <span style={{ textAlign: 'center', color: "#000" }}>LOT SIZE : {el?.displayLotQty}</span>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ textAlign: 'center', color: "#000" }}>PART DESC : {el.partDescription}</span>
                                        <br />
                                        <span style={{ textAlign: 'center', color: "#000" }}>MRN NO : {el?.mrnNo}</span>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <span style={{ textAlign: 'center', color: "#000" }}>PART NAME : {el.partName}</span>
                                        <br />
                                        <span style={{ textAlign: 'center', color: "#000" }}>SUPPLIER : {el?.supplierName.slice(0,20)}...</span>

                                    </Grid>
                                    <Grid item xs={6}>                                        
                                        <span style={{ textAlign: 'center', color: "#000" }}><b>IQC : OK</b></span>
                                    </Grid>
                                </Grid>
                                <span style={{ textAlign: 'center', color: "#000" }}>DATE : {moment(el?.createdAt).format("DD-MM-YYYY")}</span>
                            </div>
                        </div>
                    )
                })}
        </>
    )
}
