import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteBin, getAllBin, getBinByRackId } from 'app/services/Bin.service'
import { toastError } from 'app/utils/toastUtils'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function ViewBin() {
    const [blockArr, setBlockArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getBinByRackId(searchParams.get("rackId"))
            if (res) {
                setBlockArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Bin?')) {
                let {data:res}=await deleteBin(id);
                if(res){
                    getUsers()

                }
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <Button variant="contained" onClick={() => navigate(`/add-bin?storeId=${searchParams.get('storeId')}&blockId=${searchParams.get('blockId')}&rackId=${searchParams.get('rackId')}`)}>
                Add Bin
            </Button>
            <SimpleCard title={'View Bin'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Capacity</TableCell>
                            <TableCell>Current Qty</TableCell>

                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blockArr &&
                            blockArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row?.name}</TableCell>
                                    <TableCell>{row?.description}</TableCell>
                                    <TableCell>{row?.capacity}</TableCell>
                                    <TableCell>{row?.currentCapacity}</TableCell>

                                    <TableCell>
                                        <Button variant="outlined" color="error" onClick={() => handleDelete(row._id)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
