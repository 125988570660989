import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    DELETE_MOULDING_REQUIREMENT_SHEET,
    DELETE_MOULDING_REQUIREMENT_SHEET_FAIL,
    DELETE_MOULDING_REQUIREMENT_SHEET_SUCCESS,
    GET_ALL_MOULDING_REQUIREMENT_SHEET,
    GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL,
    GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS,
    MOULDING_REQUIREMENT_SHEET_ADD,
    MOULDING_REQUIREMENT_SHEET_ADD_FAIL,
    MOULDING_REQUIREMENT_SHEET_ADD_SUCCESS,
    GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES,
    GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_FAIL,
    GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_SUCCESS
} from '../../actions/RequirementSheet/MouldingRequirementSheet.action'

const initialState = {
    mouldingRequirementSheetObjectArr: [],
    mouldingRequirementSheetArr: [],
    loading: false,
    error: null,
}

export const mouldingRequirementSheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case MOULDING_REQUIREMENT_SHEET_ADD:
            return {
                ...state,
                categoryArr: null,
                loading: true,
                error: null,
            }
        case MOULDING_REQUIREMENT_SHEET_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case MOULDING_REQUIREMENT_SHEET_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_MOULDING_REQUIREMENT_SHEET:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_MOULDING_REQUIREMENT_SHEET_SUCCESS:
            return {
                ...state,
                mouldingRequirementSheetArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_MOULDING_REQUIREMENT_SHEET_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }




        case GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_SUCCESS:
            return {
                ...state,
                mouldingRequirementSheetObjectArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_MOULDING_REQUIREMENT_SHEET_DATA_BY_DATES_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case DELETE_MOULDING_REQUIREMENT_SHEET:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_MOULDING_REQUIREMENT_SHEET_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                mouldingRequirementSheetObjectArr: state.mouldingRequirementSheetObjectArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_MOULDING_REQUIREMENT_SHEET_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
