import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/product`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addProduct = (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllProduct = () => {
    return axios.get(`${url}`)
}

export const getAllMouldingProducts = (id) => {
    return axios.get(`${url}/getMouldingProducts/${id}`)
}

export const getAllPlatingProducts = (id) => {
    return axios.get(`${url}/getPlatingProducts/${id}`)
}

export const deleteProduct = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getProductById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}
export const updateProduct = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}

export const getAllAssemblyProducts = async () => {
    return axios.get(`${url}/getAssemblyProduct`)
}

export const getAllOtherProduct = async () => {
    return axios.get(`${url}/getOtherProduct`)
}

export const getAllStock = async () => {
    return await axios.get(`${url}/getallstock`)
} 
