import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import {
    deleteProductData,
    getAllProductData,
} from 'app/redux/actions/Product/product.action'
import { getAllProductStock } from 'app/services/productstock.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ViewStocks() {
    const [productArr, setProductArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllProductStock()
            if (res) {
                console.log(res.data)
                setProductArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Stocks'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Moulding Stock</TableCell>
                            <TableCell>Moulding Stock ( Qc)</TableCell>
                            <TableCell>Plating Stock</TableCell>
                            <TableCell>Plating Stock ( Qc)</TableCell>
                            <TableCell>Assembly Stock</TableCell>
                            <TableCell>Assembly Stock (Qc)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productArr &&
                            productArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>
                                        {row?.productObj?.name}
                                    </TableCell>
                                    <TableCell>{row?.mouldingStock}</TableCell>
                                    <TableCell>
                                        {row?.waitingForQcMouldingStock}
                                    </TableCell>
                                    <TableCell style={{}}>{row?.platedStock}</TableCell>
                                    <TableCell>
                                        {row?.waitingForQcPlatedStock}
                                    </TableCell>
                                    <TableCell>{row?.assembledStock}</TableCell>
                                    <TableCell>
                                        {row?.waitingForQcAssembledStock}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
