import Loadable from 'app/components/Loadable/Loadable'
import { lazy } from 'react'
import AddAssemblyProduct from './AssemblyProduct/AddAssemblyProduct'
import ViewAssemblyProduct from './AssemblyProduct/ViewAssemblyProduct'
import AddDefects from './Defects/AddDefects'
import ViewDefects from './Defects/ViewDefects'
import UpdateMachine from './Machine/UpdateMachine'
import UpdateProduct from './Product/UpdateProduct'
import AddRawMaterialStock from './RawMaterial/AddRawMaterialStock'
import UpdateRawMaterial from './RawMaterial/UpdateRawMaterial'
import ViewReportById from './Report/ViewReportById'
import ViewReports from './Report/ViewReports'
import AddRequirementSheet from './RequirementSheet/AddRequirementSheet'
import AddSchedulingSheet from './SchedulingSheet/AddSchedulingSheet'
import AddStaffAllocation from './StaffAllocation/AddStaffAllocation'
import UpdateStaffAllocation from './StaffAllocation/UpdateStaffAllocation'
import ViewStaffAllocation from './StaffAllocation/ViewStaffAllocation'
import ViewStocks from './Stocks/ViewStocks'
import UpdateCategory from './category/UpdateCategory'
import UpdateClient from './client/UpdateClient'
import AddMould from './moulding/AddMould'
import UpdateMould from './moulding/UpdateMould'
import ViewMould from './moulding/ViewMould'
import UpdateUser from './user/UpdateUser'

import EmptyPage from '../EmptyPage/EmptyPage'
import Analytics from '../dashboard/Analytics'
import AddAssemblyRequirement from './AssemblyRequirements/AddAssemblyRequirements'
import AddBin from './Bin/AddBin'
import ViewBin from './Bin/ViewBin'
import AddBlock from './Block/AddBlock'
import ViewBlock from './Block/ViewBlock'
import AddJigsMaster from './Jigs/AddJigMaster'
import AddJigs from './Jigs/AddJigs'
import ViewJigs from './Jigs/ViewJigs'
import MaterialReceiptNote from './MaterialReceiptNote/MaterialReceiptNoteAdd'
import MrnDownload from './MaterialReceiptNote/MrnDownload'
import ViewMrn from './MaterialReceiptNote/viewMrn'
import MrnQcDetails from './MrnQc/MrnQcDetails'
import ViewMrnQc from './MrnQc/ViewMrnQc'
import ViewPendingStockGroup from './PendingStockEntry/ViewPendingStockGroup'
import AddRack from './Rack/AddRack'
import ViewRack from './Rack/ViewRack'
import ViewStockLocation from './StockLocation/ViewStockLocation'
import AddStore from './Store/AddStore'
import StockIssueRequest from './Store/StockIssueRequest'
import ViewStore from './Store/ViewStore'
import ViewWaste from './WasteManagement/ViewWaste'
import ViewAllJigs from './Jigs/ViewAllJigs'
import AddSupplier from './Supplier/AddSupplier'
import ViewSupplier from './Supplier/ViewSupplier'
import AddITProducts from './ITProducts/AddITProducts'
import ViewITProducts from './ITProducts/ViewITProducts'
import DownloadItQr from './ITProducts/DownloadItQr'
import IssueItProduct from './ITProducts/IssueItProduct'
import StockCheck from './Stocks/StockCheck'
import RawMaterialIssue from './RawMaterial/RawMaterialIssue'

const AddUser = Loadable(lazy(() => import('./user/AddUser')))
const ViewUser = Loadable(lazy(() => import('./user/ViewUser')))

const AddCategory = Loadable(lazy(() => import('./category/AddCategory')))
const ViewCategory = Loadable(lazy(() => import('./category/ViewCategory')))

const AddRawMaterial = Loadable(lazy(() => import('./RawMaterial/AddRawMaterial')))
const ViewRawMaterial = Loadable(lazy(() => import('./RawMaterial/ViewRawMaterial')))

const AddProduct = Loadable(lazy(() => import('./Product/AddProduct')))
const ViewProduct = Loadable(lazy(() => import('./Product/ViewProduct')))

const AddMachine = Loadable(lazy(() => import('./Machine/AddMachine')))
const ViewMachine = Loadable(lazy(() => import('./Machine/ViewMachine')))

const AddClient = Loadable(lazy(() => import('./client/AddClient')))
const ViewClient = Loadable(lazy(() => import('./client/ViewClient')))

const adminRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics />,
    },
    {
        path: '/admin/adduser',
        element: <AddUser />,
    },
    {
        path: '/admin/viewuser',
        element: <ViewUser />,
    },
    {
        path: '/admin/updateUser/:id',
        element: <UpdateUser />,
    },
    {
        path: '/admin/addcategory',
        element: <AddCategory />,
    },
    {
        path: '/admin/viewcategory',
        element: <ViewCategory />,
    },
    {
        path: '/admin/updatecategory/:id',
        element: <UpdateCategory />,
    },
    {
        path: '/admin/addrawmaterial',
        element: <AddRawMaterial />,
    },
    {
        path: '/admin/viewrawmaterial',
        element: <ViewRawMaterial />,
    },
    {
        path: '/admin/AddrawmaterialStock/:id',
        element: <AddRawMaterialStock />,
    },
    {
        path: '/admin/updaterawmaterial/:id',
        element: <UpdateRawMaterial />,
    },
    {
        path: '/admin/addproduct',
        element: <AddProduct />,
    },
    {
        path: '/admin/viewproduct',
        element: <ViewProduct />,
    },
    {
        path: '/admin/updateproduct/:id',
        element: <UpdateProduct />,
    },
    {
        path: '/admin/addmachine',
        element: <AddMachine />,
    },
    {
        path: '/admin/viewmachine',
        element: <ViewMachine />,
    },
    {
        path: '/admin/updatemachine/:id',
        element: <UpdateMachine />,
    },
    {
        path: '/admin/addclient',
        element: <AddClient />,
    },
    {
        path: '/admin/viewclient',
        element: <ViewClient />,
    },
    {
        path: '/admin/updateclient/:id',
        element: <UpdateClient />,
    },
    {
        path: '/admin/viewmould',
        element: <ViewMould />,
    },
    {
        path: '/admin/addmould',
        element: <AddMould />,
    },
    {
        path: '/admin/updatemould/:id',
        element: <UpdateMould />,
    },
    {
        path: '/admin/addSchedule',
        element: <AddSchedulingSheet />,
    },

    {
        path: '/admin/addStaffAllocation',
        element: <AddStaffAllocation />,
    },
    {
        path: '/admin/viewStaffAllocation',
        element: <ViewStaffAllocation />,
    },
    {
        path: '/admin/updateStaffAllocation/:id',
        element: <UpdateStaffAllocation />,
    },
    {
        path: '/admin/addRequirement',
        element: <AddRequirementSheet />,
    },
    {
        path: '/admin/AddAssemblyRequirement',
        element: <AddAssemblyRequirement />,
    },
    {
        path: '/admin/reports',
        element: <ViewReports />,
    },
    {
        path: '/admin/viewReport/:id',
        element: <ViewReportById />,
    },
    {
        path: '/admin/viewassemblyProduct',
        element: <ViewAssemblyProduct />,
    },
    {
        path: '/admin/addassemblyProduct',
        element: <AddAssemblyProduct />,
    },
    {
        path: '/admin/viewstocks',
        element: <ViewStocks />,
    },
    {
        path: '/admin/ViewDefects',
        element: <ViewDefects />,
    },
    {
        path: '/admin/addDefects',
        element: <AddDefects />,
    },
    {
        path: '/material-receipt-note',
        element: <MaterialReceiptNote />,
    },
    {
        path: '/view-mrn',
        element: <ViewMrn />,
    },
    {
        path: '/download-mrn/:id',
        element: <MrnDownload />,
    },
    {
        path: '/mrn-qc/:id',
        element: <MrnQcDetails />,
    },
    {
        path: '/mrn-qc',
        element: <ViewMrnQc />,
    },
    {
        path: '/add-store',
        element: <AddStore />,
    },
    {
        path: '/view-store',
        element: <ViewStore />,
    },
    {
        path: '/view-block',
        element: <ViewBlock />,
    },
    {
        path: '/add-block',
        element: <AddBlock />,
    },
    {
        path: '/view-rack',
        element: <ViewRack />,
    },
    {
        path: '/add-rack',
        element: <AddRack />,
    },
    {
        path: '/view-bin',
        element: <ViewBin />,
    },
    {
        path: '/add-bin',
        element: <AddBin />,
    },
    {
        path: '/stock-entry',
        element: <ViewPendingStockGroup />,
    },
    {
        path: '/raw-material-request',
        element: <StockIssueRequest />,
    },
    {
        path: '/stock-location',
        element: <ViewStockLocation />,
    },

    {
        path: '/stock-check',
        element: <StockCheck />,
    },

    {
        path: '/add-jigs',
        element: <AddJigs />,
    },

    {
        path: '/view-jigs',
        element: <ViewJigs />,
    },
    {
        path: '/view-waste',
        element: <ViewWaste />,
    },
    {
        path: '/AddJigMaster',
        element: <AddJigsMaster />,
    },
    {
        path: '/ViewAllJigs',
        element: <ViewAllJigs />,
    },
    {
        path: '/EmptyPage',
        element: <EmptyPage />,
    },

    {
        path: '/AddSupplier',
        element: <AddSupplier />,
    },

    {
        path: '/ViewSupplier',
        element: <ViewSupplier />,
    },

    {
        path: '/AddItProducts',
        element: <AddITProducts />,
    },

    {
        path: '/ViewItProducts',
        element: <ViewITProducts />,
    },
    {
        path: '/IssueItProduct',
        element: <IssueItProduct />,
    },
    {
        path: '/RawMaterialIssue',
        element: <RawMaterialIssue />,
    },
    // {
    //     path: '/view-waste',
    //     element: <ViewWaste />,
    // },
]

export default adminRoutes
