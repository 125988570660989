import { SimpleCard } from 'app/components'
import { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { addRawMaterialData } from 'app/redux/actions/RawMaterials/rawmaterail.action'
import { toastError } from 'app/utils/toastUtils'
import { useDispatch } from 'react-redux'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { addRawMaterialStock } from 'app/services/RawMaterialStock.service'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))


export default function AddRawMaterialStock() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [stock, setStock] = useState(0);
    const params = useParams();


    useEffect(() => {

    }, [])



    const handleSubmit = async () => {
        try {
            let obj = {
                rawMaterialId: params.id,
                currentStock: parseInt(stock),
            }

            let { data: res } = await addRawMaterialStock(obj)
            if (res.success) {
                navigate(-1)
            }
            console.log(res, "adasda")
            // dispatch(addRawMaterialData(obj))
        } catch (error) {
            toastError(error)
        }
    }


    return (
        <Container>
            <SimpleCard title="Add Raw Material Stock">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <TextField
                        type="number"
                        name="name"
                        onChange={e => setStock(e.target.value)}
                        value={stock || ''}
                        label="Add Raw Material Stock"
                        sx={{ width: '100%', mt: 2 }}
                    />

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
