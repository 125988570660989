import { Button, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { acceptRawMaterial, getRawMaterialRequest, rejectRawMaterial } from 'app/services/rawMaterial.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import moment from 'moment'

export default function StockIssueRequest() {
    const [rawMaterialRequestArr, setRawMaterialRequestArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [scanInput, setScanInput] = useState('')
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const [selectedRequestObj, setSelectedRequestObj] = useState(null);
    const handleClose = () => setOpen(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid rgba(0,0,0,0.1)',
        boxShadow: 24,
        p: 4,
        width: '50%'
    }

    useEffect(() => {
        getRequests()
    }, [])

    const getRequests = async () => {
        try {
            let { data: res } = await getRawMaterialRequest()
            if (res) {
                console.log(res.data)
                setRawMaterialRequestArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }



    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Store?')) {
            }
        } catch (error) {
            toastError(error)
        }
        getRequests()
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))


    const handleSubmit = async (id) => {
        try {
            navigate(`/RawMaterialIssue?id=${id}`)
            // setOpen(false)
            // let obj = {
            //     id: selectedRequestObj._id
            // }
            // const { data: res } = await acceptRawMaterial(obj);
            // if (res) {
            //     toastSuccess(res.message)
            //     getRequests()
            // }
        } catch (error) {
            toastError(error)
        }
    }


    const handleReject = async (row) => {
        try {
            let obj = {
                id: row._id
            }
            const { data: res } = await rejectRawMaterial(obj);
            if (res) {
                toastSuccess(res.message)
                getRequests()
            }
        } catch (error) {
            toastError(error)
        }
    }
    const handleInputScan = (e) => {
        if (selectedRequestObj?.rawMaterialId == e) {
            setScanInput(e)
        }
        else {
            alert("Invalid Raw Material")
        }
    }

    return (
        <Container>
            <SimpleCard title={'View Requests'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Raw Material</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Shift</TableCell>
                            <TableCell>Operator</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Requested At</TableCell>
                            <TableCell>Approved At</TableCell>

                            <TableCell>Actions</TableCell>


                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rawMaterialRequestArr &&
                            rawMaterialRequestArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row?.rawMaterialName}</TableCell>
                                    <TableCell>{row?.requestQuantity}</TableCell>
                                    <TableCell>
                                        ( {row?.quantityPer} {row?.units})
                                    </TableCell>
                                    <TableCell>
                                        {row?.shiftData?.shift}
                                    </TableCell>
                                    <TableCell>
                                        {row?.shiftData?.operatorName}
                                    </TableCell>
                                    <TableCell>
                                        {row?.status}
                                    </TableCell>
                                    <TableCell>
                                        {moment(row?.createdAt).format('DD-MM-YYYY h:m')}
                                    </TableCell>
                                    <TableCell>
                                        {row?.status == "Approved" ?

                                            moment(row?.updatedAt).format('DD-MM-YYYY h:m')
                                            : "Not Approved"}
                                    </TableCell>
                                    <TableCell>
                                        <Button disabled={row?.status != "Requested"} variant="outlined" color="primary" onClick={() => {  handleSubmit(row?.rawMaterialId) }}>
                                            Action
                                        </Button>
                                       

                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Scan
                    </Typography>
                    <TextField type="text" name="name" onChange={(e) => handleInputScan(e.target.value)} value={scanInput} label={`Scan`} sx={{ width: '100%', mt: 2 }} />
                    <Button variant="outlined" color="error" sx={{ mt: 2 }} onClick={() => handleSubmit()}>
                        Issue
                    </Button>
                </Box>
            </Modal>
        </Container>
    )
}
