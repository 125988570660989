import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getBinByRackId } from 'app/services/Bin.service'
import { getByStoreId } from 'app/services/Block.service'
import { setStockLocation } from 'app/services/PendingStockGroup.service'
import { getRackByBlockId } from 'app/services/rack.service'
import { getAllStore } from 'app/services/Store.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'

export default function ViewPendingStockGroup() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [storeArr, setStoreArr] = useState([])
    const [rackArr, setRackArr] = useState([])
    const [blockArr, setBlockArr] = useState([])
    const [binArr, setBinArr] = useState([])

    const [selectedStoreObj, setSelectedStoreObj] = useState(null)
    const [selectedBlockObj, setSelectedBlockObj] = useState(null)
    const [selectedRackObj, setSelectedRackObj] = useState(null)
    const [selectedBinObj, setSelectedBinObj] = useState(null)

    const [displayArr, setDisplayArr] = useState([])

    const [inputScan, setInputScan] = useState('')

    const getStores = async () => {
        try {
            const { data: res } = await getAllStore()
            if (res) {
                let tempArr = res.data.map((el) => ({ ...el, label: el.name, value: el._id }))
                setStoreArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }

    useEffect(() => {
        getStores()
    }, [])

    const handleStoreSelect = async (event, index) => {
        try {
            const { data: res } = await getByStoreId(event.value)
            if (res) {
                setSelectedStoreObj(event)
                setBlockArr(res.data.map((el) => ({ ...el, label: el.name, value: el._id })))
                setSelectedRackObj(null)
                setSelectedBinObj(null)
                setSelectedBlockObj(null)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const handleBlockSelect = async (event, index) => {
        try {
            const { data: res } = await getRackByBlockId(event.value)
            if (res) {
                let tempArr = res.data.map((el) => ({ ...el, label: el.name, value: el._id }))
                setRackArr(tempArr)
                setSelectedRackObj(null)
                setSelectedBinObj(null)
                setSelectedBlockObj(event)
            }
        } catch (error) {
            toastError(error)
        }
    }
    const handleRackSelect = async (event, index) => {
        try {
            const { data: res } = await getBinByRackId(event.value)
            if (res) {
                let tempArr = res.data.map((el) => ({ ...el, label: el.name, value: el._id }))
                setSelectedRackObj(event)
                setSelectedBinObj(null)
                setBinArr(tempArr)
            }
        } catch (error) {
            toastError(error)
        }
    }
    const handleBinSelect = async (event) => {
        setSelectedBinObj(event)
    }

    const handleInputScan = (value) => {
        handleInputScanDebounce(value)
    }
    const handleInputScanDebounce = useCallback(
        debounce((nextVal) => {
            console.log(nextVal)
            setInputScan(nextVal)
        }, 100),
        []
    )

    const handleSubmit = async () => {
        try {
            let obj = {
                inputScan,
                storeId: selectedStoreObj?.value,
                blockId: selectedBlockObj?.value,
                rackId: selectedRackObj?.value,
                binId: selectedBinObj?.value,
                isCompleted: true,
            }

            let { data: res } = await setStockLocation(obj)
            setInputScan('')
            if (res) {
                toastSuccess(res.message)
            }
        } catch (error) {
            setInputScan('')
            toastError(error)
        }
    }

    return (
        <SimpleCard title={'View Pending Stock'}>
            <form
                onSubmit={(e) => {
                    e.preventDefault()
                }}
            >
                <Box sx={{ mb: 4 }}>
                    <Typography >Store</Typography>
                    <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={storeArr} value={selectedStoreObj} placeholder="Select Store" onChange={(e) => handleStoreSelect(e)} />
                    {blockArr && blockArr.length > 0 && (
                        <Box sx={{ mt: 2 }}>
                            <Typography>Block</Typography>
                            <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={blockArr} value={selectedBlockObj} placeholder="Select Block" onChange={(e) => handleBlockSelect(e)} />
                            {rackArr && rackArr.length > 0 && (
                                <Box sx={{ mt: 2 }}>
                                    <Typography>Rack</Typography>

                                    <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} options={rackArr} value={selectedRackObj} placeholder="Select Rack" onChange={(e) => handleRackSelect(e)} />
                                    {binArr && binArr.length > 0 && (
                                        <Box sx={{ mt: 2 }}>
                                            <Typography>Bin</Typography>

                                            <Select styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} value={selectedBinObj} options={binArr} placeholder="Select Bin" onChange={(e) => handleBinSelect(e)} />
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                <TextField value={inputScan} placeholder="Please Scan Bar Code" onChange={(e) => handleInputScan(e.target.value)} />
                <br />
                <br />
                <Button type="button" onClick={() => handleSubmit()} variant="outlined">
                    Submit
                </Button>
            </form>
        </SimpleCard>
    )
}
