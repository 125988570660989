import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    USER_ADD,
    USER_ADD_FAIL,
    USER_ADD_SUCCESS,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    GET_ALL_USERS_FAIL,
    DELETE_USERS,
    DELETE_USERS_SUCCESS,
    DELETE_USERS_FAIL,
    GET_BY_ID_USERS,
    GET_BY_ID_USERS_FAIL,
    GET_BY_ID_USERS_SUCCESS,
    UPDATE_USERS,
    UPDATE_USERS_FAIL,
    UPDATE_USERS_SUCCESS,
    GET_ALL_INSPECTOR_USERS,
    GET_ALL_INSPECTOR_USERS_FAIL,
    GET_ALL_INSPECTOR_USERS_SUCCESS,
    GET_ALL_SUPERVISOR_USERS,
    GET_ALL_SUPERVISOR_USERS_FAIL,
    GET_ALL_SUPERVISOR_USERS_SUCCESS,
} from '../../actions/User/user.actions'

const initialState = {
    userArr: [],
    supervisorUserArr: [],
    inspectorUserArr: [],
    loading: false,
    userObj: {},
    error: null,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_ADD:
            return {
                ...state,
                userArr: null,
                loading: true,
                error: null,
            }
        case USER_ADD_SUCCESS:
            toastSuccess(action.payload.message)
            return {
                ...state,
                loading: false,
                error: null,
            }
        case USER_ADD_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }


        case GET_ALL_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                userArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }




        case GET_ALL_INSPECTOR_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_INSPECTOR_USERS_SUCCESS:
            return {
                ...state,
                inspectorUserArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_INSPECTOR_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }






        case GET_ALL_SUPERVISOR_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_SUPERVISOR_USERS_SUCCESS:
            return {
                ...state,
                supervisorUserArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_SUPERVISOR_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }








        case DELETE_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case DELETE_USERS_SUCCESS:
            toastSuccess(action.payload.message)
            console.log(action.payload, "payload for delete");
            return {
                ...state,
                userArr: state.userArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case DELETE_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }





        case UPDATE_USERS:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case UPDATE_USERS_SUCCESS:
            toastSuccess(action.payload.message)
            // console.log(action.payload, "payload for delete");
            return {
                ...state,
                // userArr: state.userArr.filter(el => action.payload.data != el._id),
                loading: false,
                error: null,
            }
        case UPDATE_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }



        case GET_BY_ID_USERS:
            return {
                ...state,
                userObj: null,
                loading: true,
                error: null,
            }
        case GET_BY_ID_USERS_SUCCESS:
            // toastSuccess(action.payload.message)
            console.log(action.payload, "payload for delete");
            return {
                ...state,
                userObj: action.payload,
                loading: false,
                error: null,
            }
        case GET_BY_ID_USERS_FAIL:
            toastError(action.payload)
            return {
                ...state,
                userObj: null,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}
