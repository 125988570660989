import { toastError, toastSuccess } from 'app/utils/toastUtils'
import {
    GET_SHIFT_DATA_BY_ID,
    GET_SHIFT_DATA_BY_ID_SUCCESS,
    GET_SHIFT_DATA_BY_ID_FAIL,
    GET_ALL_SHIFT_DATA_DATA_BY_DATES,
    GET_ALL_SHIFT_DATA_DATA_BY_DATES_SUCCESS,
    GET_ALL_SHIFT_DATA_DATA_BY_DATES_FAIL,
} from '../../actions/ShiftData/ShiftData.action'

const initialState = {
    shiftDataArr: [],
    shiftObj: {},
    loading: false,
    error: null,
}

export const shiftDataReducer = (state = initialState, action) => {
    switch (action.type) {



        case GET_SHIFT_DATA_BY_ID:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_SHIFT_DATA_BY_ID_SUCCESS:
            return {
                ...state,
                shiftObj: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_SHIFT_DATA_BY_ID_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }




        case GET_ALL_SHIFT_DATA_DATA_BY_DATES:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case GET_ALL_SHIFT_DATA_DATA_BY_DATES_SUCCESS:
            return {
                ...state,
                shiftDataArr: action.payload.data,
                loading: false,
                error: null,
            }
        case GET_ALL_SHIFT_DATA_DATA_BY_DATES_FAIL:
            toastError(action.payload)
            return {
                ...state,
                loading: false,
                error: action.payload,
            }




        default:
            return state
    }
}
