import { getJwt } from 'app/services/user.service'
import axios from 'axios'

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
    function (config) {
        const token = getJwt()
        if (typeof token != 'undefined' && token !== null) {
            config.headers.common['Authorization'] = `Bearer ${token}`
        }
        return config
    },
    function (error) {
        if (error.response.status === 401) {
            // trigger logout or refresh token
        }
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || 'Something went wrong!'
        )
)

export default axiosInstance
