import axios from '../../axios';
import { url as API_URL } from './url.service';
const url = `${API_URL}/shiftData`


export const getShiftDataByIdFromDB = (id) => {
    return axios.get(`${url}/getById/${id}`)
}


export const getAllShiftDataByDate = (startDate, endDate) => {
    return axios.get(`${url}/getbydates/${startDate}/${endDate}`)
}


export const getAllProductionWaste = () => {
    return axios.get(`${url}/getProductionWaste`)
}