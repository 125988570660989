import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'
const url = `${API_URL}/staffAllocation`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addstaffAllocation = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllstaffAllocation = () => {
    return axios.get(`${url}/`)
}

export const getAllstaffAllocationById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deletestaffAllocation = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}

export const updateStaffAllocation = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
