import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'

const url = `${API_URL}/defects`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addDefects = async (obj) => {
    return axios.post(`${url}`, obj)
}

export const getAllDefects = () => {
    return axios.get(`${url}`)
}

export const getDefectsById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}

export const deleteDefectById = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const updateDefectById = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}
