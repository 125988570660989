import axios from '../../axios'
import { url as API_URL } from './url.service'
import { getJwt } from './user.service'

const url = `${API_URL}/RawMaterialStock`
let token = getJwt()
const authorisation = `Bearer ${token}`

export const addRawMaterialStock = (obj) => {
    return axios.post(`${url}/`, obj)
}

export const getAllRawMaterialStock = () => {
    return axios.get(`${url}/`)
}

export const deleteRawMaterialStock = (id) => {
    return axios.delete(`${url}/deleteById/${id}`)
}
export const getRawMaterialStockById = (id) => {
    return axios.get(`${url}/getById/${id}`)
}
export const updateRawMaterialStock = (id, obj) => {
    return axios.patch(`${url}/updateById/${id}`, obj)
}


export const getAllCurrentStock=()=>{
    return axios.get(`${url}/getAllCurrentStock`)
}