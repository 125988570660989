import axios from 'axios'
import { url } from './url.service';
import jwt_decode from "jwt-decode";
const serverUrl = url + '/user'


export const login = (formData) => {
    return axios.post(serverUrl + '/login', formData)
}

export const addUser = (obj) => {
    return axios.post(`${serverUrl}`, obj)
}

export const getAllUsers = () => {
    return axios.get(`${serverUrl}`)
}

export const getAllInspectorUsers = () => {
    return axios.get(`${serverUrl}/getAllInspectors`)
}

export const getAllSupervisorUsers = () => {
    return axios.get(`${serverUrl}/getAllSupervisors`)
}

export const getById = (id) => {
    return axios.get(`${serverUrl}/getById/${id}`)
}

export const updateUserById = (id, formdata) => {
    return axios.patch(`${serverUrl}/updateById/${id}`, formdata)
}

export const deleteUser = (id) => {
    return axios.delete(`${serverUrl}/deleteById/${id}`)
}

export const setJwt = (jwt) => {
    localStorage.setItem('access-jwt', jwt)
    return true
}

export const getJwt = () => {
    let token = localStorage.getItem('access-jwt')
    return token
}

export const decodeJwt = async () => {
    let token = localStorage.getItem('access-jwt')
    let decoded = await jwt_decode(token);
    return decoded;
}