import { Button, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { addITProducts } from 'app/services/ITProducts.service'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))

export default function AddITProducts() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const handleSubmit = async () => {
        try {

            let obj = {
                name,
                description,
            }
            let { data: res } = await addITProducts(obj)
            if (res) {
                toastSuccess(res.message)
                navigate(-1)
            }

        } catch (error) {
            toastError(error)
        }
    }

    return (
        <Container>
            <SimpleCard title="Add IT Products">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit()
                    }}
                >
                    <TextField type="text" name="name" onChange={(e) => setName(e.target.value)} value={name || ''} label="Name" sx={{ width: '100%', mt: 2 }} />

                    <TextField type="text" name="Description" onChange={(e) => setDescription(e.target.value)} value={description || ''} label="Description" sx={{ width: '100%', mt: 2 }} />

                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
