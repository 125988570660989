import { Roles } from 'Roles'

export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
        role: [Roles.admin, Roles.SUB_ADMIN, Roles.MOULDING, Roles.manager],
        children: [],
    },
    {
        label: 'Manage',
        type: 'label',
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Users',
        icon: 'person',
        children: [
            {
                name: 'Add user',
                iconText: 'AU',
                path: '/admin/adduser',
                role: [Roles.admin],
            },
            {
                name: 'View User',
                iconText: 'VU',
                path: '/admin/viewuser',
                role: [Roles.admin],
            },
        ],
        role: [Roles.admin],
    },
    {
        name: 'Moulding',
        icon: 'category',
        children: [
            {
                name: 'Category',
                iconText: 'AC',
                path: '/admin/addcategory',
                children: [
                    {
                        name: 'Add Category',
                        iconText: 'AC',
                        path: '/admin/addcategory',

                        role: [Roles.admin],
                    },
                    {
                        name: 'View Category',
                        iconText: 'AC',
                        path: '/admin/viewcategory',

                        role: [Roles.admin, Roles.manager],
                    }
                ],
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Mould',
                iconText: 'AC',
                path: "/admin/addmould",
                children: [
                    {
                        name: 'Add',
                        iconText: 'A',
                        path: '/admin/addmould',
                        role: [Roles.admin, Roles.manager],
                    },
                    {
                        name: 'Manage',
                        iconText: 'M',
                        path: '/admin/viewmould',
                        role: [Roles.admin, Roles.manager],
                    },
                ],
                role: [Roles.admin, Roles.manager],
            }

        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Product',
        icon: 'category',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addproduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/viewproduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View Stocks',
                iconText: 'V',
                path: '/admin/viewstocks',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Machine',
        icon: 'category',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addmachine',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/viewmachine',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Staff Allocation',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addStaffAllocation',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewStaffAllocation',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },

    {
        name: 'Raw Materials',
        icon: 'category',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addrawmaterial',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/admin/viewrawmaterial',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Issue Raw Material',
                iconText: 'V',
                path: '/RawMaterialIssue',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },


    {
        name: 'IT Products',
        icon: 'category',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/AddItProducts',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'View',
                iconText: 'V',
                path: '/ViewItProducts',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Issue',
                iconText: 'V',
                path: '/IssueItProduct',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },

    {
        name: 'Client',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addclient',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewclient',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Requirements',
                iconText: 'A',
                path: '/admin/addRequirement',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Mould',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addmould',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewmould',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    {
        name: 'Assembly Product',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addassemblyProduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'M',
                path: '/admin/viewassemblyProduct',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Assembly Requirements',
                iconText: 'M',
                path: '/admin/AddAssemblyRequirement',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
    // {
    //     name: 'Requirement',
    //     icon: 'person',
    //     children: [

    //     ],
    // },
    {
        name: 'Scheduling',
        icon: 'person',
        children: [
            {
                name: 'Manage',
                iconText: 'A',
                path: '/admin/addSchedule',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },

    {
        name: 'Defects',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/admin/addDefects',
                role: [Roles.admin, Roles.manager],
            },
            {
                name: 'Manage',
                iconText: 'A',
                path: '/admin/ViewDefects',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },

    {
        name: 'MRN',
        icon: 'person',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/material-receipt-note',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'View',
                iconText: 'A',
                path: '/view-mrn',
                role: [Roles.admin, Roles.manager, Roles.STORE, Roles.STORE_EXE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE, Roles.STORE_EXE],
    },
    {
        name: 'MRN QC',
        icon: 'list',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/mrn-qc',
                role: [Roles.admin, Roles.manager, Roles.STORE, Roles.QC_PDI, Roles.STORE_EXE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE, Roles.QC_PDI, Roles.STORE_EXE],
    },
    {
        name: 'Store',
        icon: 'list',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/add-store',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'View',
                iconText: 'A',
                path: '/view-store',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'Raw Material Request',
                iconText: 'A',
                path: '/raw-material-request',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Supplier',
        icon: 'list',
        children: [
            {
                name: 'Add',
                iconText: 'A',
                path: '/AddSupplier',
                role: [Roles.admin, Roles.STORE],
            },
            {
                name: 'View',
                iconText: 'A',
                path: '/ViewSupplier',
                role: [Roles.admin, Roles.STORE],
            },

        ],
        role: [Roles.admin, Roles.STORE],
    },
    {
        name: 'Waste Management',
        icon: 'list',
        children: [

            {
                name: 'View',
                iconText: 'A',
                path: '/view-waste',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },

        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Stock Entry',
        icon: 'list',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/stock-entry',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Stock Location',
        icon: 'list',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/stock-location',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },
    {
        name: 'Stock Check',
        icon: 'list',
        children: [
            {
                name: 'View',
                iconText: 'A',
                path: '/stock-check',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },


    {
        name: 'Jigs',
        icon: 'list',
        children: [
            {
                name: 'Add Jig Master',
                iconText: 'A',
                path: '/AddJigMaster',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            // {
            //     name: 'Add',
            //     iconText: 'A',
            //     path: '/add-jigs',
            //     role: [Roles.admin, Roles.manager, Roles.STORE],
            // },
            {
                name: 'Manage Master',
                iconText: 'B',
                path: '/view-jigs',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
            {
                name: 'All Jigs',
                iconText: 'B',
                path: '/ViewAllJigs',
                role: [Roles.admin, Roles.manager, Roles.STORE],
            },
        ],
        role: [Roles.admin, Roles.manager, Roles.STORE],
    },

    {
        name: 'Report',
        icon: 'person',
        children: [
            {
                name: 'View Reports',
                iconText: 'A',
                path: '/admin/reports',
                role: [Roles.admin, Roles.manager],
            },
        ],
        role: [Roles.admin, Roles.manager],
    },
]
