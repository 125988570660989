import { getShiftDataByIdFromDB, getAllShiftDataByDate } from '../../../services/shiftData.service'


/////////////////////////////////////GET USER
export const GET_SHIFT_DATA_BY_ID = 'GET_ALL_SHIFT_DATA'
export const GET_SHIFT_DATA_BY_ID_SUCCESS = 'GET_ALL_SHIFT_DATA_SUCCESS'
export const GET_SHIFT_DATA_BY_ID_FAIL = 'GET_ALL_SHIFT_DATA_FAIL'



/////////////////////////////////////GET USER
export const GET_ALL_SHIFT_DATA_DATA_BY_DATES = 'GET_ALL_SHIFT_DATA_DATA_BY_DATES'
export const GET_ALL_SHIFT_DATA_DATA_BY_DATES_SUCCESS = 'GET_ALL_SHIFT_DATA_DATA_BY_DATES_SUCCESS'
export const GET_ALL_SHIFT_DATA_DATA_BY_DATES_FAIL = 'GET_ALL_SHIFT_DATA_DATA_BY_DATES_FAIL'



export const getShiftDataById = (formData) => async (dispatch) => {
    try {
        dispatch({ type: GET_SHIFT_DATA_BY_ID })
        let { data: response } = await getShiftDataByIdFromDB(formData)
        console.log(response, "response")
        if (response) {
            dispatch({
                type: GET_SHIFT_DATA_BY_ID_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_SHIFT_DATA_BY_ID_FAIL, payload: err })
    }
}

export const getAllShiftDataByDates = (startDate, endDate) => async (dispatch) => {
    try {
        dispatch({ type: GET_ALL_SHIFT_DATA_DATA_BY_DATES })
        let { data: response } = await getAllShiftDataByDate(startDate, endDate)
        console.log(response, "response")

        if (response) {
            dispatch({
                type: GET_ALL_SHIFT_DATA_DATA_BY_DATES_SUCCESS,
                payload: {
                    ...response,
                },
            })
        }
    } catch (err) {
        console.error(err)
        dispatch({ type: GET_ALL_SHIFT_DATA_DATA_BY_DATES_FAIL, payload: err })
    }
}
