import React, { useEffect, useState } from 'react'
import { SimpleCard } from 'app/components'
import { Box, styled } from '@mui/system'
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Icon,
    TableRow,
    Button,
} from '@mui/material'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import { deleteRawMaterial, getAllRawMaterial } from 'app/services/rawMaterial.service'
import { getAllProduct, deleteProduct } from 'app/services/product.service'
import { useDispatch, useSelector } from 'react-redux'
import { deleteProductData, getAllProductData } from 'app/redux/actions/Product/product.action'
import { deleteUserData } from 'app/redux/actions/User/user.actions'
import { deletestaffAllocationData, getAllstaffAllocationData } from 'app/redux/actions/StaffAllocation/StaffAllocation.action'
import { useNavigate } from 'react-router-dom'

export default function ViewStaffAllocation() {
    const productArr = useSelector(state => state.staffAllocation.staffAllocationArr);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            dispatch(getAllstaffAllocationData())
        } catch (error) {
            toastError(error)
        }
    }

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }




    const handleDelete = async (id) => {
        try {
            if (window.confirm('Are you sure you want to delete this Staff allocation?')) {
                dispatch(deletestaffAllocationData(id))
            }
        } catch (error) {
            toastError(error)
        }
        getUsers()
    }
    const handleUpdate = async (id) => {
        try {
            navigate(`/admin/updateStaffAllocation/${id}`)

        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Staff Allocations'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Shift</TableCell>
                            <TableCell>Machine</TableCell>
                            <TableCell>Supervisor</TableCell>
                            <TableCell>Inspector</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productArr && productArr.map((row, index) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell>
                                    Shift {row?.shift}
                                </TableCell>
                                <TableCell>
                                    {row?.machineObj?.name ? row?.machineObj?.name : "NA"}
                                </TableCell>
                                <TableCell>
                                    {row?.supervisorObj?.name ? row?.supervisorObj?.name : "NA"}
                                </TableCell>
                                <TableCell>
                                    {row?.inspectorObj?.name ? row?.inspectorObj?.name : "NA"}
                                </TableCell>

                                <TableCell><Button variant='outlined' color='primary' onClick={() => handleUpdate(row._id)}>Update</Button></TableCell>
                                <TableCell><Button variant='outlined' color='error' onClick={() => handleDelete(row._id)}>Delete</Button></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
