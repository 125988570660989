import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { deleteProductData, getAllProductData } from 'app/redux/actions/Product/product.action'
import { getStockLocation } from 'app/services/PendingStockGroup.service'
import { getAllStore } from 'app/services/Store.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function ViewStockLocation() {
    const [stockLocationArr, setStockLocationArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getStockLocation()
            if (res) {
                console.log(res.data, 'DATA')
                setStockLocationArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'Stock'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Units</TableCell>
                            <TableCell>Quantity Per</TableCell>

                            <TableCell>Store</TableCell>
                            <TableCell>Block</TableCell>
                            <TableCell>Rack</TableCell>
                            <TableCell>Bin</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stockLocationArr &&
                            stockLocationArr.length > 0 &&
                            stockLocationArr.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>{row?.partNo}</TableCell>
                                    <TableCell>{row?.qty}</TableCell>
                                    <TableCell>{row?.units}</TableCell>
                                    <TableCell>{row?.quantityPer}</TableCell>

                                    <TableCell>{row?.storeName}</TableCell>
                                    <TableCell>{row?.blockName}</TableCell>
                                    <TableCell>{row?.rackName}</TableCell>
                                    <TableCell>{row?.binName}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
