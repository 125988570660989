import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
// import AutocompleteCombo from './AutocompleteCombo'
// import AsyncAutocomplete from './AsyncAutocomplete'
// import BadgeAutocomplete from './BadgeAutocomplete'
import { Box, styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import { getAllMachineData } from 'app/redux/actions/Machines/machine.action'
import { getAllCategoryData } from 'app/redux/actions/MouldingCategories/mouldingCategory.action'
import { addProductData } from 'app/redux/actions/Product/product.action'
import { addstaffAllocationData } from 'app/redux/actions/StaffAllocation/StaffAllocation.action'
import { getAllInspectorUserData, getAllSupervisorUserData, getAllUserData } from 'app/redux/actions/User/user.actions'
import { addProduct } from 'app/services/product.service'
import { getAllstaffAllocationById, updateStaffAllocation } from 'app/services/StaffAllocation'
import { PRODUCT_TYPES } from 'app/utils/constant'
import { toastError, toastSuccess } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '16px',
        },
    },
}))



export default function UpdateStaffAllocation() {
    const machineArr = useSelector(state => state.machine.machineArr);
    const superVisorArr = useSelector(state => state.user.supervisorUserArr);
    const inspectorArr = useSelector(state => state.user.inspectorUserArr);
    const params = useParams()
    const dispatch = useDispatch();
    const [machineId, setMachineId] = useState('')
    const [shift, setShift] = useState("A");
    const [supervisorId, setSupervisorId] = useState('');
    const [inspectorId, setInspectorId] = useState("");
    useEffect(() => {
        getDataOnInit()

    }, [])

    const getDataOnInit = async () => {
        try {
            dispatch(getAllMachineData())
            dispatch(getAllSupervisorUserData())
            dispatch(getAllInspectorUserData())
            getStaffAllocationDataById()
        } catch (error) {
            toastError(error)
        }
    }


    const handleSubmit = async () => {
        try {
            if (machineId == "") {
                alert("Please select a machine")
            }
            else if (supervisorId == "") {
                alert("Please select a Supervisor")
            }
            else if (inspectorId == "") {
                alert("Please select an Inspector")
            }
            else {

                let obj = {
                    machineId,
                    shift,
                    supervisorId,
                    inspectorId,
                }
                let { data: res } = await updateStaffAllocation(params.id, obj)
                if (res.success) {
                    alert(res.message)
                }
                // dispatch(addstaffAllocationData(obj))
            }

        } catch (error) {
            toastError(error)
        }
    }


    useEffect(() => {
        getStaffAllocationDataById()
    }, [machineArr, superVisorArr, inspectorArr]) // s

    const getStaffAllocationDataById = async () => {
        try {
            let { data: res } = await getAllstaffAllocationById(params.id);
            {
                setMachineId(res.data.machineId);
                setSupervisorId(res.data.supervisorId);
                setInspectorId(res.data.inspectorId);
                setShift(res.data.shift)
            }
        }
        catch (err) {
            toastError(err)
        }
    }




    return (
        <Container>
            <SimpleCard title="Update Staff Allocation">
                <form onSubmit={(e) => { e.preventDefault(); handleSubmit() }}>
                    <Box>
                        <FormControl sx={{ mt: 3 }}>
                            <FormLabel id="demo-radio-buttons-group-label">Shift</FormLabel>
                            <RadioGroup
                                sx={{ mb: 2 }}
                                value={shift || ''}
                                name="Shift"
                                onChange={e => setShift(e.target.value)}
                                row
                            >
                                <FormControlLabel
                                    value={"A"}
                                    control={<Radio color="primary" />}
                                    label="Shift A"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value={"B"}
                                    control={<Radio color="primary" />}
                                    label="Shift B"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>


                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Machines</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={machineId}
                                label="Machine"
                                onChange={e => setMachineId(e.target.value)}
                            >
                                {
                                    machineArr?.map(el =>
                                        <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Supervisor</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={supervisorId}
                                label="Supervisor"
                                onChange={e => setSupervisorId(e.target.value)}
                            >
                                {
                                    superVisorArr?.map(el =>
                                        <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>


                    <Box sx={{ mt: 3 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Inspector</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={inspectorId}
                                label="Inspector"
                                onChange={e => setInspectorId(e.target.value)}
                            >
                                {
                                    inspectorArr?.map(el =>
                                        <MenuItem value={el._id} key={el._id}>{el.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>



                    <Button variant="contained" color="primary" sx={{ mt: 2 }} type="submit">
                        Submit
                    </Button>
                </form>
            </SimpleCard>
        </Container>
    )
}
