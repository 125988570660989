import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material'
import { styled } from '@mui/system'
import { SimpleCard } from 'app/components'
import {
    deleteProductData,
    getAllProductData,
} from 'app/redux/actions/Product/product.action'
import { getStockData } from 'app/services/PendingStockGroup.service'
import { getAllCurrentStock, getAllRawMaterialStock } from 'app/services/RawMaterialStock.service'
import { getAllStock } from 'app/services/product.service'
import { getAllProductStock } from 'app/services/productstock.service'
import { toastError } from 'app/utils/toastUtils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function StockCheck() {
    const [productArr, setProductArr] = useState([])
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
    }, [])

    const getUsers = async () => {
        try {
            let { data: res } = await getAllCurrentStock()
            if (res) {
                setProductArr(res.data)
            }
        } catch (error) {
            toastError(error)
        }
    }

    const removeSpace = (val) => {
        return val.replace('_', ' ')
    }

    const Container = styled('div')(({ theme }) => ({
        margin: '30px',
        [theme.breakpoints.down('sm')]: {
            margin: '16px',
        },
        '& .breadcrumb': {
            marginBottom: '30px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '16px',
            },
        },
    }))

    return (
        <Container>
            <SimpleCard title={'View Stocks'}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Description</TableCell>

                            <TableCell>Category</TableCell>
                            <TableCell>Part No.</TableCell>
                            {/* <TableCell>Unit</TableCell> */}
                            <TableCell>Units</TableCell>
                            <TableCell>Current Stock</TableCell>

                            <TableCell>Total Qty</TableCell>

                            {/* <TableCell>Assembly Stock (Qc)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productArr &&
                            productArr.map((row, index) => (
                                <TableRow
                                    key={row.name}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell>
                                        {row?.name}
                                    </TableCell>
                                    <TableCell>{row?.description}</TableCell>
                                    <TableCell>
                                        {row?.category}
                                    </TableCell>
                                    <TableCell>{row?.partNo}</TableCell>
                                    {/* <TableCell >{row?.units}</TableCell> */}
                                    <TableCell >{row?.quantityPer} {row?.units}</TableCell>
                                    <TableCell style={{ color: row?.currentStock <= 1000 ? "red" : "black" }}>{row?.currentStock}</TableCell>
                                    <TableCell>{row?.currentStock * row?.quantityPer}</TableCell>
                                    {/* <TableCell>
                                        {row?.waitingForQcAssembledStock}
                                    </TableCell> */}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </SimpleCard>
        </Container>
    )
}
